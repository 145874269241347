import { baseApi } from 'core/api/base'
import { generatePath } from 'react-router'
import { IClient } from 'core/interfaces/User'

const KEYCLOAK_UTILISATEURS_ENDPOINT = '/clients'
const KEYCLOAK_UTILISATEUR_ENDPOINT = '/clients/:id'

const clientApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllClients: builder.query<IClient[], void>({
      query: () => ({
        url: generatePath(KEYCLOAK_UTILISATEURS_ENDPOINT),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Client', id }) as const),
              { type: 'Client', id: 'LIST' },
            ]
          : [{ type: 'Client', id: 'LIST' }],
    }),
    getClient: builder.query<IClient, { id: string }>({
      query: ({ id }) =>
        generatePath(KEYCLOAK_UTILISATEUR_ENDPOINT, {
          id: id,
        }),
      providesTags: (result, error, { id }) => [{ type: 'Client', id }],
    }),
  }),
})

export const { useGetAllClientsQuery, useGetClientQuery } = clientApi
export { clientApi }
