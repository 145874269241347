import { baseApi } from 'core/api/base'
import { generatePath } from 'react-router'
import { IKeycloakUtilisateur } from 'core/interfaces/User'

const KEYCLOAK_UTILISATEURS_ENDPOINT = '/keycloak-utilisateurs'
const KEYCLOAK_UTILISATEUR_ENDPOINT = '/keycloak-utilisateurs/:id'

const keycloakUtilisateursApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllKeycloakUsers: builder.query<
      IKeycloakUtilisateur[],
      { search?: string; page?: number }
    >({
      query: ({ search = '', page = 1 }) => ({
        url: generatePath(KEYCLOAK_UTILISATEURS_ENDPOINT),
        params: { search, page },
      }),
    }),
    getKeycloakUser: builder.query<IKeycloakUtilisateur, { id: string }>({
      query: ({ id }) =>
        generatePath(KEYCLOAK_UTILISATEUR_ENDPOINT, {
          id: id,
        }),
    }),
    updateKeycloakUser: builder.mutation<
      IKeycloakUtilisateur,
      {
        id: string
        tagsUtilisateur: number[]
        tagsAdministrateur: number[]
      }
    >({
      query: ({ id, tagsUtilisateur, tagsAdministrateur }) => ({
        url: generatePath(KEYCLOAK_UTILISATEUR_ENDPOINT, {
          id: id,
        }),
        method: 'PATCH',
        body: { tagsUtilisateur, tagsAdministrateur },
      }),
    }),
  }),
})

export const {
  useGetAllKeycloakUsersQuery,
  useGetKeycloakUserQuery,
  useUpdateKeycloakUserMutation,
} = keycloakUtilisateursApi
export { keycloakUtilisateursApi }
