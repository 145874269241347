import ModalWrapper from 'core/components/common/ModalWrapper'
import { ModalHeader } from 'core/components/common/ModalHeader'
import { ModalBody } from 'core/components/common/ModalBody'
import React, { FC, SetStateAction } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IPrompt } from 'core/interfaces/Prompt'
import Loader from 'core/components/common/Loader'

export const ChangePromptStatusModal: FC<{
  prompt: IPrompt
  open: boolean
  setOpen: (value: SetStateAction<boolean>) => void
  handleClick: () => void
  isLoading: boolean
}> = ({ prompt, open, setOpen, handleClick, isLoading }) => {
  const hide = () => {
    setOpen(false)
  }
  const { t } = useTranslation()

  return (
    <ModalWrapper
      classNames={{ modal: 'modal modal-remove-prompt' }}
      onClose={hide}
      open={open}
      showCloseIcon={false}
    >
      <ModalHeader>{t('prompts.modalChangeStatusPrompt.title')}</ModalHeader>
      <ModalBody>
        <div className="flex flex-col px-[30px]">
          <div>
            {isLoading ? (
              <Loader pageCenter />
            ) : (
              <Trans
                i18nKey={
                  prompt?.actif
                    ? 'prompts.modalChangeStatusPrompt.textInactive'
                    : 'prompts.modalChangeStatusPrompt.textActive'
                }
                components={{ strong: <strong className="font-medium" /> }}
              />
            )}
          </div>
          <div className="text-right mt-[30px] self-end">
            <button className="small-btn cancel hidden" onClick={hide}>
              {t('global.form.button.cancel')}
            </button>
            <button
              className="small-btn default w-full md:w-auto ml-[20px]"
              onClick={() => handleClick()}
            >
              {prompt?.actif
                ? t('prompts.modalChangeStatusPrompt.inactive')
                : t('prompts.modalChangeStatusPrompt.active')}
            </button>
          </div>
        </div>
      </ModalBody>
    </ModalWrapper>
  )
}
