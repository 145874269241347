import { TagsOptions } from 'lauria/components/tags/TagsOptions'
import { useGetAllTagsByClientWithApplicationsQuery } from 'core/api/tag'
import React, { useContext, useEffect, useState } from 'react'
import { CurrentTagContext } from 'lauria/contexts/CurrentTagContext'
import { TagsAppsList } from 'lauria/components/tags/TagsAppsList'
import { useTranslation } from 'react-i18next'
import { ReactComponent as TagIcon } from 'lauria/assets/images/tags/tagIcone.svg'
import { ReactComponent as PlusButton } from 'lauria/assets/images/mobile/plusButton.svg'
import { ReactComponent as PlusButtonDark } from 'lauria/assets/images/mobile/plusButtonDark.svg'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import { ITag } from 'core/interfaces/Tag'
import { FormTagModal } from 'lauria/components/tags/FormTagModal'
import { DeleteTagModal } from 'lauria/components/tags/DeleteTagModal'
import Loader from 'core/components/common/Loader'
import { UserContext } from 'core/contexts/UserContext'
import { skipToken } from '@reduxjs/toolkit/query'
import { AddButton } from 'core/components/common/AddButton'

export const TagsAdmin = () => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)

  const { user: currentUser } = useContext(UserContext)

  const [hidden, setHidden] = useState(false)

  const [editTag, setEditTag] = useState<ITag | null>(null)
  const [openEdit, setOpenEdit] = useState<boolean>(false)

  const [deleteTag, setDeleteTag] = useState<ITag | null>(null)
  const [openDelete, setOpenDelete] = useState<boolean>(false)

  const { currentTag, setCurrentTag } = useContext(CurrentTagContext)

  const idClient = currentUser?.idClient
  const {
    data: tags,
    isLoading,
    isFetching,
  } = useGetAllTagsByClientWithApplicationsQuery(
    idClient ? { idClient } : skipToken
  )

  useEffect(() => {
    if (tags && tags.length > 0) {
      setCurrentTag(tags[0])
    }
  }, [tags])

  const displayTagApps = (tag: ITag) => {
    if (tag?.applicationsCount && tag?.applicationsCount > 0) {
      setCurrentTag(tag)
      setHidden(true)
    }
  }

  const handleCreate = () => {
    setEditTag(null)
    setOpenEdit(true)
  }

  const handleEdit = (tag: ITag) => {
    setEditTag(tag)
    setOpenEdit(true)
  }

  const handleDelete = (tag: ITag) => {
    setDeleteTag(tag)
    setOpenDelete(true)
  }

  if (isLoading || isFetching) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader center />
      </div>
    )
  }

  if (!tags) {
    return null
  }

  return (
    tags && (
      <div className="tags-admin">
        <div
          className={`tags-options-wrapper relative flex-col ${hidden ? 'md:flex hidden' : 'flex'}`}
        >
          <div className="w-full flex justify-center items-center">
            <AddButton
              onClick={() => handleCreate()}
              label={t('tags.form.create.button')}
              classname="mt-[20px]"
            />
          </div>
          <div className="tag-title options">
            <TagIcon className="md:hidden flex tag-icon mr-[20px]" />
            {t('tags.optionTitle')}
          </div>
          <TagsOptions
            tags={tags}
            displayTagApps={displayTagApps}
            currentTag={currentTag}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
        <div
          className={`tag-apps-list-wrapper ${hidden ? 'flex' : 'md:flex hidden'}`}
        >
          <TagsAppsList setHidden={setHidden} />
        </div>
        <div
          className={`md:hidden z-40 bottom-[20px] right-[20px] ${hidden ? 'hidden' : 'absolute'}`}
        >
          {isDarkTheme ? (
            <PlusButtonDark onClick={() => setOpenEdit(true)} />
          ) : (
            <PlusButton onClick={() => setOpenEdit(true)} />
          )}
        </div>
        <FormTagModal open={openEdit} setOpen={setOpenEdit} tag={editTag} />
        <DeleteTagModal
          open={openDelete}
          setOpen={setOpenDelete}
          tag={deleteTag}
        />
      </div>
    )
  )
}
