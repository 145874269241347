import React from 'react'
import { IApplicationPromptVariable } from 'core/interfaces/Application'
import { ApplicationIcon } from 'lauria/components/applications/ApplicationIcon'

export const ConversationHeader: React.FC<{
  application: IApplicationPromptVariable
}> = ({ application }) => {
  return (
    <div className="sub-header">
      <div className="flex flex-row items-center justify-center pr-[20px] pl-[10px] h-full pb-[20px] border-b border-gray-1200">
        <ApplicationIcon
          service={application.service}
          smallSize
          withBg={false}
        />
        <div className="flex flex-col flex-grow mt-[2px] overflow-hidden">
          <div className="app-name">{application.nomApplication}</div>
          <div className="description">{application.nomApplication}</div>
        </div>
      </div>
    </div>
  )
}
