import React, { useContext } from 'react'
import { ReactComponent as LogoLauria } from 'core/assets/images/general/logoLauria.svg'
import { useTranslation } from 'react-i18next'
import Loader from 'core/components/common/Loader'
import { ApplicationCard } from 'lauria/components/dashboard/ApplicationCard'
import { useAppListIastart } from 'iastart/hooks/useAppListIastart'
import { ReactComponent as PrevIcon } from 'core/assets/images/icones/prevIcon.svg'
import { ReactComponent as NextIcon } from 'core/assets/images/icones/nextIcon.svg'
import { ReactComponent as PrevIconDark } from 'core/assets/images/icones/prevIconDark.svg'
import { ReactComponent as NextIconDark } from 'core/assets/images/icones/nextIconDark.svg'
import Slider from 'react-slick'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'

export const IaStart = () => {
  const { t } = useTranslation()
  const {
    isLoading,
    lastApplications,
    applicationsFiltered,
    applications,
    handleClick,
    tags,
    selected,
    handleTagClick,
  } = useAppListIastart()
  const { isDarkTheme } = useContext(DarkThemeContext)
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: isDarkTheme ? (
      <NextIconDark className="text-black" />
    ) : (
      <NextIcon className="text-gray-1075" />
    ),
    prevArrow: isDarkTheme ? (
      <PrevIconDark className="text-black" />
    ) : (
      <PrevIcon className="text-gray-1075" />
    ),
  }

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }

  return (
    <div className="iastart-wrapper">
      <div className="flex flex-col md:w-[1000px] w-full md:px-0 px-[20px]">
        <LogoLauria className="h-[40px] w-fit mt-[20px]" />
        <div className="mt-10px mb-[30px]">{t('iastart.textIntro')}</div>
        {lastApplications.length > 0 && (
          <>
            {applications && applications.length > 3 && (
              <div className="card-list-title">
                {t('iastart.recentsAppTitle')}
              </div>
            )}
            <div className="card-list">
              {lastApplications.map((app, index) => (
                <ApplicationCard
                  application={app}
                  key={index}
                  withLink
                  handleClick={() => handleClick(app)}
                />
              ))}
            </div>
          </>
        )}
        {applications && applications.length > 3 && (
          <>
            {tags && tags.length > 1 && (
              <Slider {...settings}>
                {tags.map((tag, index) => {
                  return (
                    <div
                      className="px-[15px] pt-[10px] justify-items-center  hover:dark:bg-gray-1425 hover:bg-white-150"
                      key={index}
                    >
                      <div
                        className={`tag-tab ${selected === tag.id ? 'active' : ''}`}
                        onClick={() => handleTagClick(tag.id)}
                      >
                        {tag.nomTag}
                      </div>
                    </div>
                  )
                })}
              </Slider>
            )}
            <div className="card-list ml-[30px] w-fit">
              {applicationsFiltered.map((app, index) => (
                <ApplicationCard
                  application={app}
                  key={index}
                  withLink
                  handleClick={() => handleClick(app)}
                  withBg={false}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
