import { useTranslation } from 'react-i18next'

export const PromptStateTag: React.FC<{
  actif: boolean
}> = ({ actif }) => {
  const { t } = useTranslation()

  return (
    <div className={`tag-state ${actif ? 'actif' : 'inactif'}`}>
      {actif ? t('prompts.actif') : t('prompts.inactif')}
    </div>
  )
}
