import { useTranslation } from 'react-i18next'
import { ReactComponent as IconPublish } from 'core/assets/images/icones/iconPublish.svg'
import { ReactComponent as IconUnpublish } from 'core/assets/images/icones/iconUnpublish.svg'
import { ReactComponent as IconCopy } from 'core/assets/images/icones/copyIcon.svg'
import React, { useContext, useState } from 'react'
import { IApplication } from 'core/interfaces/Application'
import { usePublishApplicationMutation } from 'core/api/application'
import { toast } from 'core/components/common/Toast'
import Loader from 'core/components/common/Loader'
import { defaultEnv } from 'core/constants/currentEnv'
import envConstants from 'core/constants/envConstants'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'

export const FormPublicationApplicationModalContent: React.FC<{
  cancelAction: (event: any) => void
  setModalOpen: (value: boolean) => void
  application: IApplication
}> = ({ cancelAction, setModalOpen, application }) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)

  const [isPublished, setIsPublished] = useState<boolean>(application.publiee)

  const [publishApplication, { isLoading }] = usePublishApplicationMutation()

  const url =
    envConstants[defaultEnv].LAURIA_URL + '/app/' + application.nomTechnique

  const handleCopy = () => {
    navigator.clipboard.writeText(url)
  }

  const handlePublish = () => {
    publishApplication({
      idApplication: application.id,
      publiee: isPublished,
    })
      .unwrap()
      .then(() => {
        toast.success(
          t(
            isPublished
              ? 'applications.form.publication.successPublish'
              : 'applications.form.publication.successUnpublish'
          )
        )
        setModalOpen(false)
      })
  }

  if (isLoading) {
    return <Loader center />
  }

  return (
    <form onSubmit={handlePublish} className="form-publication">
      <p className="mb-[10px]">
        {t('applications.form.publication.description')}
      </p>

      <div className="form-publication-buttons">
        <button
          type="button"
          className={`button-publish ${!isPublished ? 'selected' : ''}`}
          onClick={() => setIsPublished(false)}
        >
          <div className="flex">
            <IconUnpublish
              className={`${isDarkTheme ? 'text-black' : 'text-white'}`}
            />
            <span className="flex items-center ml-[10px]">
              {t('applications.form.publication.unpublish')}
            </span>
          </div>
        </button>

        <button
          type="button"
          className={`button-unpublish ${isPublished ? 'selected' : ''}`}
          onClick={() => setIsPublished(true)}
        >
          <div className="flex">
            <IconPublish
              className={`${isDarkTheme ? 'text-black' : 'text-white'}`}
            />
            <span className="flex items-center ml-[10px]">
              {t('applications.form.publication.publish')}
            </span>
          </div>
        </button>
      </div>
      <div className="flex flex-row justify-end mt-[10px]">
        <p>{envConstants[defaultEnv].LAURIA_URL}/app/...</p>
        <button type="button" className="flex ml-[5px]" onClick={handleCopy}>
          <IconCopy className="cursor-pointer" />
        </button>
      </div>
      <div className={`text-right self-end mt-[20px] w-full md:block`}>
        <button className="btn cancel hidden mr-30px" onClick={cancelAction}>
          {t('global.form.button.cancel')}
        </button>
        <input
          type="submit"
          className="btn default w-full md:w-auto"
          value={t('applications.form.publication.buttonSubmit')}
        />
      </div>
    </form>
  )
}
