import { IPromptDetail } from 'core/interfaces/Prompt'
import { ReactComponent as PromptIconBanner } from 'lauria/assets/images/prompts/promptIconBanner.svg'
import { ReactComponent as InactivePromptIconBanner } from 'lauria/assets/images/prompts/inactiveBannerIconPrompt.svg'
import { ReactComponent as CheckedIcon } from 'core/assets/images/icones/checkedIcon.svg'
import { ReactComponent as CheckedIconDark } from 'core/assets/images/icones/checkedIconDark.svg'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { NbDocIcon } from 'lauria/components/common/NbDocIcon'

export const KpiPromptCard: React.FC<{
  prompt: IPromptDetail
  classname?: string
  selected?: boolean
  error?: boolean
  handleClick: () => void
}> = ({
  prompt,
  classname = '',
  selected = false,
  error = false,
  handleClick,
}) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <div className="flex flex-col w-[280px] mr-[20px]">
      <div
        className={`kpi-prompt-card ${classname} ${error ? 'error' : ''}`}
        onClick={handleClick}
      >
        {selected && <div className={`left-border ${classname}`}></div>}
        <div
          className={`kpi-prompt-card-content ${selected ? 'absolute left-[8px] ' + classname : ''}`}
        >
          <div className="flex flex-row items-center">
            <div className="flex flex-col justify-center items-center w-[70px]">
              {prompt.actif ? (
                <PromptIconBanner className="kpi-prompt-icon" />
              ) : (
                <InactivePromptIconBanner className="kpi-prompt-icon" />
              )}
              {prompt.documentsCount > 0 && (
                <NbDocIcon
                  nbDoc={prompt.documentsCount}
                  className="h-[15px] w-[40px] mt-[5px]"
                  iconClassName="h-[9px] w-[9px]"
                />
              )}
            </div>
            <div className="uppercase line-clamp-2">{prompt.nomPrompt}</div>
          </div>

          {selected && (
            <div className="flex items-center justify-center">
              {isDarkTheme ? (
                <CheckedIconDark className="text-blue-1500" />
              ) : (
                <CheckedIcon className="text-blue-1500" />
              )}
            </div>
          )}
        </div>
      </div>
      {error && (
        <div className="kpi-prompt-card-error">
          {t('prompts.kpi.errorSelection')}
        </div>
      )}
    </div>
  )
}
