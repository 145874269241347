import { DarkThemeToggle } from 'core/components/layout/DarkThemeToggle'
import React, { useContext } from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router'
import { ReactComponent as LogoLauria } from 'core/assets/images/general/logoLauria.svg'
import { ReactComponent as LogoLauriaBack } from 'core/assets/images/general/logoLauriaBack.svg'
import { ReactComponent as LogoLauriaBackMini } from 'core/assets/images/general/logoLauriaBackMini.svg'
import { ReactComponent as LogoIaDocsMini } from 'core/assets/images/logos/logoIaDocs.svg'
import { ReactComponent as IconeProfile } from 'lauria/assets/images/common/iconeProfile.svg'
import { UserContext } from 'core/contexts/UserContext'
import { BACKOFFICE_PATH, ROUTE_PATHS } from 'core/constants/routePath'
import { SmallLogoLink } from 'core/components/common/SmallLogoLink'

export const LayoutHeader: React.FC<{
  displayProfileIcone?: boolean
}> = ({ displayProfileIcone = false }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isAdmin, isSuperAdmin } = useContext(UserContext)
  const pathname = location.pathname
  const isBackoffice = pathname.startsWith(BACKOFFICE_PATH)
  const goHome = () => {
    if (isBackoffice) {
      navigate(generatePath(BACKOFFICE_PATH))
    } else {
      navigate(generatePath('/'))
    }
  }
  const goToProfiles = () => {
    navigate(generatePath(ROUTE_PATHS.PROFILES))
  }

  const goToBackoffce = () => {
    navigate(generatePath(BACKOFFICE_PATH))
  }

  return (
    <div className="layout-header">
      <div>
        {isBackoffice ? (
          pathname !== ROUTE_PATHS.DASHBOARD ? (
            <SmallLogoLink backoffice />
          ) : (
            <LogoLauriaBack className="logo-lauria" onClick={goHome} />
          )
        ) : pathname !== ROUTE_PATHS.IASTART ? (
          <SmallLogoLink />
        ) : (
          <LogoLauria className="logo-lauria" onClick={goHome} />
        )}
      </div>
      <div className="option-header">
        <DarkThemeToggle />
        {(isAdmin || isSuperAdmin) && displayProfileIcone && (
          <IconeProfile className="profile-option" onClick={goToProfiles} />
        )}
        {!isBackoffice && (
          <div className="flex flex-row items-center">
            <LogoIaDocsMini
              className="logo-lauria-back-mini"
              onClick={goToBackoffce}
            />
            <LogoLauriaBackMini
              className="logo-lauria-back-mini"
              onClick={goToBackoffce}
            />
          </div>
        )}
      </div>
    </div>
  )
}
