import {
  useCreateUpdatePromptMutation,
  useDeletePromptMutation,
  useGetPromptQuery,
} from 'lauria/api/prompt'
import { skipToken } from '@reduxjs/toolkit/query'
import { generatePath, useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { promptLayout } from 'lauria/store/slices/promptLayout'
import { useState } from 'react'
import { ROUTE_PATHS } from 'core/constants/routePath'

export const usePromptContent = (
  promptId: number | null,
  refetchPrompts: () => {}
) => {
  const {
    data: prompt,
    isLoading: isLoadingPrompt,
    isFetching: isFetchingPrompt,
    refetch: refetchPrompt,
  } = useGetPromptQuery(promptId ? { promptId } : skipToken)
  const [deletePrompt, { isLoading: isDeleting }] = useDeletePromptMutation()
  const navigate = useNavigate()
  const { application } = useSelector(promptLayout)
  const [openRemoveModal, setOpenRemoveModal] = useState(false)
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false)
  const [createUpdatePrompt, { isLoading: isUpdating }] =
    useCreateUpdatePromptMutation()
  const changeStatus = () => {
    if (prompt) {
      const formData = new FormData()
      formData.append('actif', !prompt.actif ? 'true' : 'false')
      formData.append('idPrompt', prompt.id.toString())
      createUpdatePrompt({ data: formData })
        .unwrap()
        .then(() => {
          refetchPrompt()
          refetchPrompts()
          setOpenChangeStatusModal(false)
        })
    }
  }
  const handleAppClick = () => {
    navigate(
      generatePath(ROUTE_PATHS.PROMPT_EDIT, {
        idApplication: application?.id,
        idPrompt: prompt?.id,
      })
    )
  }

  const handleDelete = () => {
    if (prompt) {
      deletePrompt(prompt.id)
        .unwrap()
        .then(() => {
          refetchPrompts()
          setOpenRemoveModal(false)
        })
    }
  }

  return {
    changeStatus,
    handleAppClick,
    openRemoveModal,
    setOpenRemoveModal,
    openChangeStatusModal,
    setOpenChangeStatusModal,
    isUpdating,
    isLoading: isLoadingPrompt || isFetchingPrompt,
    prompt,
    handleDelete,
    isDeleting,
  }
}
