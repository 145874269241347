import { ServiceSelect } from 'lauria/components/dashboard/ServiceSelect'
import { TagSelect } from 'lauria/components/dashboard/TagSelect'
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TagWithDeleteButton } from 'lauria/components/common/TagWithDeleteButton'

export const ApplicationFilters: React.FC<{
  setSelectedTags: Dispatch<SetStateAction<{ id: number; label: string }[]>>
  selectedTags: { id: number; label: string }[]
  service: string
  setService: Dispatch<SetStateAction<string>>
  setFiltersApplied: Dispatch<SetStateAction<boolean>>
}> = ({
  setSelectedTags,
  selectedTags,
  service,
  setService,
  setFiltersApplied,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    setFiltersApplied(service !== '' || selectedTags.length > 0)
  }, [service, selectedTags])

  const handleTagRemove = (id: number) => {
    setSelectedTags(
      selectedTags.filter((tag: { id: number; label: string }) => tag.id !== id)
    )
  }
  const filterAppByService = (event: ChangeEvent<HTMLSelectElement>) => {
    setService(event.target.value)
  }

  return (
    <div className="m-[20px] ">
      <div className="font-semibold text-[18px] text-gray-1325 mb-4">
        {t('applications.filter.title')}
      </div>
      <div className="flex flex-col">
        <div className="filter-card mb-30px">
          <ServiceSelect
            boldLabel
            placeholder={t('applications.filter.servicePlaceholder')}
            onChange={filterAppByService}
            value={service}
            displayDefaultOption
            required={false}
            labelKey="applications.filter.serviceLabel"
            width="full"
          />
        </div>
        <div className="filter-card">
          <TagSelect
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            boldLabel
            optionLink
            width="full"
          />
          {selectedTags.length > 0 && (
            <div className="pt-[5px]">
              <div className="flex flex-wrap gap-2 mt-2">
                {selectedTags.map((tag) => (
                  <TagWithDeleteButton
                    key={tag.id}
                    tag={tag}
                    onClick={handleTagRemove}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
