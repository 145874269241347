import React from 'react'
import { PromptForm } from 'lauria/components/prompts/PromptForm'
import { PromptNavigation } from 'lauria/components/prompts/PromptNavigation'
import { useSelector } from 'react-redux'
import { promptLayout } from 'lauria/store/slices/promptLayout'
import { PromptConversationProvider } from 'lauria/contexts/PromptConversationContext'

export const PromptEdition = () => {
  const { application } = useSelector(promptLayout)

  if (!application) {
    return null
  }

  return (
    <div className="prompt-page">
      <PromptNavigation application={application} />

      <div className="prompt-content">
        <PromptConversationProvider>
          <PromptForm service={application.service} />
        </PromptConversationProvider>
      </div>
    </div>
  )
}
