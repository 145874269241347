import { IService } from 'core/interfaces/Service'
import { ServiceCard } from 'lauria/components/applications/ServiceCard'
import { FieldLabel } from 'core/components/common/FieldLabel'
import { useTranslation } from 'react-i18next'
import { FieldError, UseFormRegister } from 'react-hook-form'
import { ApplicationForm } from 'lauria/hooks/useEditApplication'
import React from 'react'

export const ServiceCardList: React.FC<{
  services: IService[]
  selectedService: IService | null
  handleSelectService: (service: IService) => void
  register: UseFormRegister<ApplicationForm>
  error: FieldError | undefined
}> = ({ services, selectedService, handleSelectService, register, error }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col">
      <div className="flex flex-row h-[45px] items-center">
        <FieldLabel label={t('applications.form.cardServiceLabel')} required />
      </div>
      <div className="service-card-list">
        {services.map((service, index) => (
          <ServiceCard
            service={service}
            key={index}
            isSelected={selectedService?.id === service.id}
            onClick={handleSelectService}
            error={error}
          />
        ))}
        <input type="hidden" {...register('idService', { required: true })} />
      </div>
      {error?.type === 'required' && (
        <p className="form-error">
          {t('applications.form.errors.selectService')}
        </p>
      )}
    </div>
  )
}
