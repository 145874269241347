import { baseApi } from 'core/api/base'
import { generatePath } from 'react-router'
import { IService } from 'core/interfaces/Service'

const SERVICES_ENDPOINT = '/services'
const serviceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllService: builder.query<IService[], void>({
      query: () => generatePath(SERVICES_ENDPOINT),
    }),
  }),
})

export const { useGetAllServiceQuery } = serviceApi
export { serviceApi }
