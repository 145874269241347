import { IClient, IKeycloakUtilisateur } from 'core/interfaces/User'
import React, { createContext, ReactNode } from 'react'
import { KEYCLOAK_ROLE_SUPERADMIN } from 'core/constants/role'
import { UseKeycloakService } from 'core/packages/KeycloakProvider'

interface IUserContextType {
  clients: IClient[]
  setClients: (clients: IClient[]) => void
  isAdmin: boolean
  isSuperAdmin: boolean
  user: IKeycloakUtilisateur | null
  setCurrentUser: (user: IKeycloakUtilisateur | null) => void
  updateUser: (partialUser: Partial<IKeycloakUtilisateur>) => void
  getClientId: () => number | null
}

export const UserContext = createContext<IUserContextType>({
  clients: [],
  setClients: () => {},
  isAdmin: false,
  isSuperAdmin: false,
  user: null,
  setCurrentUser: () => {},
  updateUser: () => {},
  getClientId: () => null,
})

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const kc = UseKeycloakService()
  const isSuperAdmin = kc.checkRole(KEYCLOAK_ROLE_SUPERADMIN)

  const [clients, setClients] = React.useState<IClient[]>([])
  const [user, setUser] = React.useState<IKeycloakUtilisateur | null>(null)
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false)

  const setCurrentUser = (user: IKeycloakUtilisateur | null) => {
    setUser(user)
    setIsAdmin((user?.tagsAdministrateur ?? []).length > 0)
  }

  const updateUser = (partialUser: Partial<IKeycloakUtilisateur>) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...partialUser,
    }))
  }
  const getClientId = () => {
    return user?.idClient ?? null
  }

  return (
    <UserContext.Provider
      value={{
        clients,
        setClients,
        isAdmin,
        isSuperAdmin,
        user,
        setCurrentUser,
        updateUser,
        getClientId,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
