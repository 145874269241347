import { useGetAllServiceQuery } from 'lauria/api/service'
import { useParams } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/query'
import {
  useCreateApplicationMutation,
  useGetApplicationQuery,
  useUpdateApplicationMutation,
} from 'core/api/application'
import { useContext, useEffect, useState } from 'react'
import { IService } from 'core/interfaces/Service'
import { useForm } from 'react-hook-form'
import { generatePath, useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { toast } from 'core/components/common/Toast'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'core/contexts/UserContext'
import {
  ERROR_DUPLICATE_NOM_APPLICATION,
  ERROR_DUPLICATE_NOM_TECHNIQUE,
} from 'core/constants/error'

export type ApplicationForm = {
  intitule: string
  nomTechnique: string
  description: string
  accroche: string
  idService: string
  tags: string
}
export const useEditApplication = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { user: currentUser } = useContext(UserContext)

  const { data: services, isLoading: isLoadingServices } =
    useGetAllServiceQuery()
  const { idApplication } = useParams()
  const applicationId = idApplication ? parseInt(idApplication, 10) : undefined
  const creation = applicationId === undefined
  const [createApplication, { isLoading: isLoadingCreateApplication }] =
    useCreateApplicationMutation()
  const [updateApplication, { isLoading: isLoadingUpdateApplication }] =
    useUpdateApplicationMutation()
  const {
    data: application,
    isLoading: isLoadingApp,
    isFetching,
  } = useGetApplicationQuery(applicationId ? { applicationId } : skipToken)
  const [selectedService, setSelectedService] = useState<IService | null>(null)
  const [selectedTags, setSelectedTags] = useState<
    { id: number; label: string }[]
  >([])
  const state = {
    selectedService,
    setSelectedService,
    selectedTags,
    setSelectedTags,
  }
  const initialValues = {
    intitule: application?.nomApplication || '',
    nomTechnique: application?.nomTechnique || '',
    description: application?.description || '',
    accroche: application?.accroche || '',
    idService: application?.service.id.toString() || '',
    tags:
      application?.tags && application.tags.length > 0
        ? JSON.stringify(application?.tags)
        : '',
  }

  const defaultResolver = async (data: ApplicationForm) => {
    const errors: any = {}
    if (!data.intitule) {
      errors.intitule = { type: 'required', message: '' }
    }
    if (!data.description) {
      errors.description = { type: 'required', message: '' }
    }
    if (!data.accroche) {
      errors.accroche = { type: 'required', message: '' }
    }
    if (!data.nomTechnique) {
      errors.nomTechnique = { type: 'required', message: '' }
    }
    if (!data.idService) {
      errors.idService = { type: 'required', message: '' }
    }
    if (!data.tags) {
      errors.tags = { type: 'required', message: '' }
    }

    return {
      values: Object.keys(errors).length ? {} : data,
      errors,
    }
  }
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid, dirtyFields },
    setValue,
    getValues,
  } = useForm<ApplicationForm>({
    defaultValues: initialValues,
    resolver: defaultResolver,
  })
  const handleIntituleBlur = () => {
    if (!application) {
      const intituleValue = getValues('intitule')
      if (intituleValue) {
        if (!dirtyFields.accroche) {
          setValue(
            'accroche',
            t('applications.form.accrocheDefault', { intitule: intituleValue })
          )
        }
        if (!dirtyFields.nomTechnique) {
          const formattedValue = intituleValue
            .toLowerCase()
            .replace(/\s+/g, '_')
            .replace(/[^a-z0-9_]/g, '')
          setValue('nomTechnique', formattedValue)
        }
      }
    }
  }

  useEffect(() => {
    if (application && application.tags) {
      setSelectedTags(
        application.tags.map((tag) => ({ id: tag.id, label: tag.nomTag }))
      )
    }
  }, [application])
  useEffect(() => {
    setValue(
      'tags',
      selectedTags.length > 0 ? JSON.stringify(selectedTags) : ''
    )
  }, [selectedTags])
  const handleSelectService = (service: IService) => {
    setSelectedService(selectedService?.id === service.id ? null : service)
    setValue('idService', service.id.toString())
  }
  useEffect(() => {
    setSelectedService(application?.service || null)
  }, [application])
  const handleTagRemove = (id: number) => {
    setSelectedTags(
      selectedTags.filter((tag: { id: number; label: string }) => tag.id !== id)
    )
  }
  const cancelAction = () => {
    if (creation) {
      navigate(generatePath(ROUTE_PATHS.DASHBOARD))
    } else {
      navigate(
        generatePath(ROUTE_PATHS.PROMPTS, { idApplication: applicationId })
      )
    }
  }

  const catchErrors = (error: any) => {
    if (error.status === 409) {
      if (error.data?.detail === ERROR_DUPLICATE_NOM_APPLICATION) {
        setError('intitule', {
          type: 'custom',
          message: t('applications.form.errors.duplicateIntitule'),
        })
      }
      if (error.data?.detail === ERROR_DUPLICATE_NOM_TECHNIQUE) {
        setError('nomTechnique', {
          type: 'custom',
          message: t('applications.form.errors.duplicateNomTechnique'),
        })
      }
    }
  }

  const onSubmit = handleSubmit((data) => {
    if (application) {
      updateApplication({
        idApplication: application.id,
        idService: parseInt(data.idService),
        nomApplication: data.intitule,
        tags: selectedTags.map((tag) => tag.id),
        description: data.description,
        accroche: data.accroche,
      })
        .unwrap()
        .then((application) => {
          if (application.id) {
            navigate(
              generatePath(ROUTE_PATHS.PROMPTS, {
                idApplication: application.id,
              })
            )
            toast.success(t('applications.form.edit.success'))
          }
        })
        .catch(catchErrors)
    } else {
      const idClient = currentUser?.idClient
      if (!idClient) {
        return
      }

      createApplication({
        idClient: idClient,
        idService: parseInt(data.idService),
        nomApplication: data.intitule,
        nomTechnique: data.nomTechnique,
        tags: selectedTags.map((tag) => tag.id),
        description: data.description,
        accroche: data.accroche,
      })
        .unwrap()
        .then((application) => {
          if (application.id) {
            navigate(
              generatePath(ROUTE_PATHS.PROMPTS, {
                idApplication: application.id,
              })
            )
            toast.success(t('applications.form.success'))
          }
        })
        .catch(catchErrors)
    }
  })
  return {
    services,
    isLoading:
      isLoadingServices ||
      isLoadingApp ||
      isFetching ||
      isLoadingCreateApplication ||
      isLoadingUpdateApplication,
    state,
    handleSelectService,
    register,
    errors,
    handleSubmit,
    handleTagRemove,
    creation,
    isValid,
    cancelAction,
    onSubmit,
    handleIntituleBlur,
  }
}
