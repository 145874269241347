import { useTranslation } from 'react-i18next'
import React from 'react'
import { useEditProfile } from 'lauria/hooks/useEditProfile'
import Loader from 'core/components/common/Loader'
import { generatePath } from 'react-router'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { NavLink } from 'react-router-dom'
import { ReactComponent as BackArrowIcon } from 'core/assets/images/icones/backArrow.svg'
import { ProfileDescription } from 'lauria/components/profiles/ProfileDescription'
import { ProfileRadio } from 'lauria/components/profiles/ProfileRadio'
import { ProfileSelectMobile } from 'lauria/components/profiles/ProfileSelectMobile'

export const ProfileEdition = () => {
  const { t } = useTranslation()

  const {
    user: profile,
    isLoading,
    onSubmit,
    currentUserTagAdmin,
    cancelAction,
    roleFields,
    updateRole,
  } = useEditProfile()

  if (isLoading) {
    return (
      <Loader
        center
        className="h-screen flex items-center justify-center w-full"
      />
    )
  }

  return (
    <div className="profile-card">
      <div className="profile-title">
        {profile && (
          <div>
            <div className="capitalize">{`${profile.firstName ?? ''} ${profile.lastName ?? ''}`}</div>
            <div>{profile.email}</div>
          </div>
        )}
      </div>
      <form className="form-profile" onSubmit={onSubmit}>
        <span className="md:hidden flex my-[20px] ml-[25px] text-blue-1350">
          <NavLink to={generatePath(ROUTE_PATHS.PROFILES)} className="navLink">
            <BackArrowIcon className="icon" />
            <span>{t('profiles.return')}</span>
          </NavLink>
        </span>
        <div className="form-content-profile">
          <div className="flex md:flex-row flex-col"></div>
          {currentUserTagAdmin && (
            <>
              <div className="md:flex hidden flex-row w-full mt-[40px]">
                <div className="w-1/2">
                  <ProfileRadio
                    currentUserTagAdmin={currentUserTagAdmin}
                    fields={roleFields}
                    update={updateRole}
                  />
                </div>
                <div className="pr-[20px]">
                  <ProfileDescription />
                </div>
              </div>
              <div className="md:hidden flex flex-row w-full mt-[40px]">
                <ProfileSelectMobile
                  currentUserTagAdmin={currentUserTagAdmin}
                  fields={roleFields}
                  update={updateRole}
                />
              </div>
            </>
          )}
        </div>
        <div className="profile-buttons">
          <button className="btn cancel hidden mr-30px" onClick={cancelAction}>
            {t('global.form.button.cancel')}
          </button>
          <input
            type="submit"
            className="btn default profile-btn-submit"
            value={t('profiles.submit')}
          />
        </div>
      </form>
    </div>
  )
}
