import { ReactComponent as Avatar } from 'lauria/assets/images/common/avatar.svg'
import { ReactComponent as AvatarDarkMobile } from 'lauria/assets/images/common/avatarDarkMobile.svg'
import { FC } from 'react'
import { useUserCard } from 'lauria/hooks/useUserCard'

export const UserCardMobile: FC<{ username: string }> = ({ username }) => {
  const {
    isDarkTheme,
    clients,
    selectedClient,
    handleClientChange,
    isSuperAdmin,
  } = useUserCard()

  return (
    <div className="card-user-mobile">
      {isDarkTheme ? (
        <AvatarDarkMobile />
      ) : (
        <Avatar width="45px" height="45px" />
      )}
      <div className="flex flex-col ml-[10px]">
        <div>{username}</div>
        <select
          className="border rounded p-1"
          value={selectedClient?.id ?? ''}
          onChange={handleClientChange}
          disabled={!isSuperAdmin}
        >
          {clients.map((client) => (
            <option key={client.id} value={client.id}>
              {client.nomClient}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
