import React from 'react'
import { ITagUser } from 'core/interfaces/User'
import { FieldArrayWithId, UseFieldArrayUpdate } from 'react-hook-form'
import { ProfileForm } from 'lauria/hooks/useEditProfile'
import { useTranslation } from 'react-i18next'
import {
  ROLE_ADMIN_VALUE,
  ROLE_DESACTIVE_VALUE,
  ROLE_USER_VALUE,
} from 'core/constants/role'

export const ProfileSelectMobile: React.FC<{
  currentUserTagAdmin: (ITagUser | undefined)[] | undefined
  fields: FieldArrayWithId<ProfileForm, 'roles', 'id'>[]
  update: UseFieldArrayUpdate<ProfileForm, 'roles'>
}> = ({ currentUserTagAdmin, fields, update }) => {
  const { t } = useTranslation()
  return (
    <table className="profile-role-edition">
      <thead>
        <tr>
          <th className="font-medium text-left">{t('profiles.tagLabel')}</th>
          <th className="font-medium">{t('profiles.typeAccessLabel')}</th>
        </tr>
      </thead>
      <tbody>
        {fields.map((field: any, index: number) => (
          <tr key={index} className="h-[50px]">
            <td className="my-[10px] flex ">
              <div className="tag-role">
                {
                  currentUserTagAdmin?.find((tag) => tag?.id === field.idTag)
                    ?.nomTag
                }
              </div>
            </td>
            <td className="text-right">
              <select
                className="form-input w-[150px] h-[30px]"
                value={field.idRole}
                onChange={(e) =>
                  update(index, {
                    idTag: field.idTag,
                    idRole: Number(e.target.value),
                  })
                }
              >
                <option value={ROLE_DESACTIVE_VALUE}>
                  {t('profiles.roleDesactiveLabel')}
                </option>
                <option value={ROLE_USER_VALUE}>
                  {t('profiles.userLabel')}
                </option>
                <option value={ROLE_ADMIN_VALUE}>
                  {t('profiles.adminLabel')}
                </option>
              </select>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
