import React, { useContext, useState } from 'react'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import { IApplication } from 'core/interfaces/Application'
import { getIconeInfo } from 'core/utils/service'
import { ReactComponent as AppPublicationIcon } from 'core/assets/images/icones/appPublication.svg'
import { FormPublicationApplicationModal } from 'lauria/components/applications/FormPublicationApplicationModal'

interface PromptHeaderProps {
  application: IApplication
}

export const ApplicationHeaderMobile: React.FC<PromptHeaderProps> = ({
  application,
}) => {
  const { isDarkTheme } = useContext(DarkThemeContext)
  const { svgPathDark, svgPath } = getIconeInfo(application.service)
  const { nomApplication, service } = application

  const [openPublication, setOpenPublication] = useState<boolean>(false)

  const openModalPublication = () => {
    setOpenPublication(true)
  }

  return (
    <div className="prompt-header">
      <div className="flex flex-row">
        <img src={isDarkTheme ? svgPathDark : svgPath} alt="Banner Icon" />
        <div className="flex flex-col ml-10px">
          <div className="uppercase text-gray-1250">{service?.nomService}</div>
          <div className="font-medium">{nomApplication}</div>
        </div>
      </div>
      <div className="md:hidden">
        <div
          className={`btn-publication ${application.publiee ? 'publiee' : 'non-publiee'}`}
          onClick={openModalPublication}
        >
          <AppPublicationIcon className="h-[20px] mr-[5px]" />
        </div>
      </div>
      <FormPublicationApplicationModal
        open={openPublication}
        setOpen={setOpenPublication}
        application={application}
      />
    </div>
  )
}
