import { baseApi } from 'core/api/base'
import { generatePath } from 'react-router'
import {
  IApplication,
  IApplicationPromptVariable,
} from 'core/interfaces/Application'

const CLIENT_APPLICATIONS_ENDPOINT = '/clients/:idClient/applications'
const CLIENT_APPLICATIONS_BYTAGS_ENDPOINT =
  '/clients/:idClient/applications/by-tags'

export const APPLICATIONS_ENDPOINT = '/applications'
export const APPLICATION_ENDPOINT = `${APPLICATIONS_ENDPOINT}/:applicationId`
export const PUBLISH_APPLICATION_ENDPOINT = `${APPLICATION_ENDPOINT}/publier`
export const APPLICATION_PROMPT_VARIABLE_ENDPOINT = `/clients/:idClient/applications/:applicationName/prompt-variables`

const applicationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllApplicationsByClientFilterByTags: builder.query<
      IApplication[],
      {
        idClient: number
        tags?: number[]
        services?: number[]
        emptyTag?: boolean
      }
    >({
      query: ({ idClient, tags = [], services = [], emptyTag = false }) => {
        const params = new URLSearchParams()

        tags.forEach((tag) => {
          params.append('tags.id[]', tag.toString())
        })

        services.forEach((service) => {
          params.append('service.id[]', service.toString())
        })

        if (emptyTag) {
          params.append('empty[tags]', 'true')
        }

        const queryString = params.toString()
        return `${generatePath(CLIENT_APPLICATIONS_BYTAGS_ENDPOINT, { idClient: idClient.toString() })}${queryString !== '' ? '?' + queryString : ''}`
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Application', id }) as const),
              { type: 'Application', id: 'LIST' },
            ]
          : [{ type: 'Application', id: 'LIST' }],
    }),
    getAllApplicationsByClient: builder.query<
      IApplication[],
      {
        idClient: number
        published?: boolean
      }
    >({
      query: ({ idClient, published }) => {
        const url = generatePath(CLIENT_APPLICATIONS_ENDPOINT, {
          idClient: idClient.toString(),
        })

        const searchParams = new URLSearchParams()
        if (published !== undefined) {
          searchParams.append('publiee', String(published))
        }

        return {
          url: `${url}?${searchParams.toString()}`,
          method: 'GET',
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Application', id }) as const),
              { type: 'Application', id: 'LIST' },
            ]
          : [{ type: 'Application', id: 'LIST' }],
    }),
    getApplication: builder.query<IApplication, { applicationId: number }>({
      query: ({ applicationId }) =>
        generatePath(APPLICATION_ENDPOINT, {
          applicationId: applicationId.toString(),
        }),
      providesTags: (result, error, { applicationId }) => [
        { type: 'Application', applicationId },
      ],
    }),
    createApplication: builder.mutation<
      IApplication,
      {
        idClient: number
        idService: number
        nomApplication: string
        nomTechnique: string
        tags?: number[]
        description: string
        accroche: string
      }
    >({
      query: ({
        idClient,
        idService,
        nomApplication,
        nomTechnique,
        tags,
        description,
        accroche,
      }) => ({
        url: generatePath(APPLICATIONS_ENDPOINT),
        method: 'POST',
        body: {
          idClient,
          idService,
          nomApplication,
          nomTechnique,
          tags,
          description,
          accroche,
        },
      }),
      invalidatesTags: [{ type: 'Application', id: 'LIST' }],
    }),
    updateApplication: builder.mutation<
      IApplication,
      {
        idApplication: number
        idService: number
        nomApplication: string
        tags?: number[]
        description: string
        accroche: string
      }
    >({
      query: ({
        idApplication,
        idService,
        nomApplication,
        tags,
        description,
        accroche,
      }) => ({
        url: generatePath(APPLICATION_ENDPOINT, {
          applicationId: idApplication.toString(),
        }),
        method: 'PATCH',
        body: { idService, nomApplication, tags, description, accroche },
      }),
      invalidatesTags: (result, error, { idApplication }) => [
        { type: 'Application', idApplication },
      ],
    }),
    getApplicationPromptVariablesByClient: builder.query<
      IApplicationPromptVariable,
      {
        idClient: number
        applicationName: string
      }
    >({
      query: ({ idClient, applicationName }) =>
        generatePath(APPLICATION_PROMPT_VARIABLE_ENDPOINT, {
          idClient: idClient.toString(),
          applicationName,
        }),
    }),
    publishApplication: builder.mutation<
      IApplication,
      {
        idApplication: number
        publiee: boolean
      }
    >({
      query({ idApplication, publiee }) {
        return {
          url: generatePath(PUBLISH_APPLICATION_ENDPOINT, {
            applicationId: idApplication.toString(),
          }),
          method: 'PATCH',
          body: { publiee },
        }
      },
      invalidatesTags: (result, error, { idApplication }) => [
        { type: 'Application', idApplication },
      ],
    }),
  }),
})

export const {
  useGetAllApplicationsByClientFilterByTagsQuery,
  useGetAllApplicationsByClientQuery,
  useGetApplicationQuery,
  useCreateApplicationMutation,
  useUpdateApplicationMutation,
  useGetApplicationPromptVariablesByClientQuery,
  usePublishApplicationMutation,
} = applicationApi
export { applicationApi }
