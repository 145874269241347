import {
  SubmitHandler,
  useController,
  useFieldArray,
  useForm,
  UseFormGetValues,
} from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import { useAskPromptMutation } from 'core/api/ask'
import { PromptForm } from 'core/interfaces/Prompt'
import { IModeleAnswer, IPromptAskResponse } from 'core/interfaces/Ask'
import { IDocument } from 'core/interfaces/Document'
import { useProcessFile } from 'core/hooks/useProcessFile'
import { usePromptConversation } from 'lauria/contexts/PromptConversationContext'
import { UserType } from 'core/interfaces/Conversation'
import { useTranslation } from 'react-i18next'

export type PromptTestFormData = {
  fields: { name: string; value: string }[]
  modele1: string
  modele2?: string
}

export const usePromptTest = (
  getFormPromptValues: UseFormGetValues<PromptForm>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [askPrompt] = useAskPromptMutation()
  const { convertToBase64 } = useProcessFile()
  const [variables, setVariables] = useState<string[]>([])
  const [documentsTest, setDocumentsTest] = useState<IDocument[]>([])
  const { setConversation } = usePromptConversation()
  const { t } = useTranslation()
  const extractVariablesFromPrompt = (): string[] => {
    const regex = /(\{[a-zA-Z0-9]+\})/g
    const matches = []
    let match

    const text = getFormPromptValues('prompt') || ''

    while ((match = regex.exec(text)) !== null) {
      matches.push(match[1].trim())
    }

    return matches
  }
  useEffect(() => {
    setVariables(extractVariablesFromPrompt())
  }, [])
  const refreshVars = () => {
    setVariables(extractVariablesFromPrompt())
  }

  useEffect(() => {
    const promptValue = getFormPromptValues('prompt')

    if (promptValue !== undefined) {
      refreshVars()
    }
  }, [getFormPromptValues('prompt')])
  const { control, handleSubmit, register, getValues, setValue } =
    useForm<PromptTestFormData>({
      defaultValues: {
        fields: variables.map((field) => ({ name: field, value: '' })),
        modele1: getFormPromptValues('modele') || '',
        modele2: '',
      },
    })
  const { fields, replace } = useFieldArray({
    control,
    name: 'fields',
  })
  const modele1Field = useController({
    name: 'modele1',
    control,
    rules: { required: true },
    defaultValue: getFormPromptValues('modele') || '',
  }).field

  useEffect(() => {
    const modeleValue = getFormPromptValues('modele')
    if (modeleValue) {
      setValue('modele1', modeleValue)
    }
  }, [getFormPromptValues, setValue])
  useController({
    name: 'modele2',
    control,
    rules: { required: false },
  })
  useEffect(() => {
    replace(variables.map((variable) => ({ name: variable, value: '' })))
  }, [variables, replace])
  const onSubmit: SubmitHandler<PromptTestFormData> = async (data) => {
    setIsLoading(true)
    let text = getFormPromptValues('prompt') || ''

    data.fields.forEach((field) => {
      const regex = new RegExp(`${field.name}`, 'g')
      text = text.replace(regex, field.value || '')
    })
    const bodyBase = {
      prompt: text,
      temperature: getFormPromptValues('temperature') ?? 0.5,
      documents: [] as string[],
    }
    bodyBase.documents = (await Promise.all(
      documentsTest.map(async (doc) => {
        if (doc.file) {
          const base64 = await convertToBase64(doc?.file)
          return 'data:' + doc.mimeType + ';base64,' + base64
        }
      })
    )) as string[]
    try {
      const promises: Array<{
        promise: Promise<IPromptAskResponse>
        modele: string
      }> = []
      const startTimes: Record<string, number> = {}
      const endTimes: Record<string, number> = {}

      if (data.modele1) {
        startTimes['modele1'] = performance.now()
        promises.push({
          promise: askPrompt({ ...bodyBase, modele: data.modele1 })
            .unwrap()
            .then((result) => {
              endTimes['modele1'] = performance.now() // Enregistrez le temps de fin pour modele1
              return result
            }),
          modele: 'modele1',
        })
      }

      if (data.modele2) {
        startTimes['modele2'] = performance.now()
        promises.push({
          promise: askPrompt({ ...bodyBase, modele: data.modele2 })
            .unwrap()
            .then((result) => {
              endTimes['modele2'] = performance.now()
              return result
            }),
          modele: 'modele2',
        })
      }

      const results = await Promise.allSettled(promises.map((p) => p.promise))
      let answers: IModeleAnswer[] = []
      results.forEach((result, index) => {
        const modele = promises[index].modele
        const elapsedTime = endTimes[modele] - startTimes[modele]

        if (result.status === 'fulfilled') {
          answers.push({
            answer: result.value,
            time: elapsedTime,
            modeleName: index === 0 ? data.modele1 : data.modele2,
          })
        } else {
          console.error(`Erreur avec modele${index + 1}:`, result.reason)
        }
      })
      setConversation((prev) => ({
        messages: [
          ...prev.messages,
          { type: UserType.USER, content: text, timestamp: Date.now() },
          {
            type: UserType.ASSISTANT,
            content: t('prompts.form.promptResultText'),
            timestamp: Date.now(),
          },
          { answers: answers },
        ],
      }))
      setIsLoading(false)
    } catch (error) {
      console.error('Erreur lors des appels API:', error)
    }
  }

  return {
    register,
    handleSubmit,
    fields,
    onSubmit,
    replace,
    getFormTestValues: getValues,
    modele1Field,
    variables,
    refreshVars,
    documentsTest,
    setDocumentsTest,
  }
}
