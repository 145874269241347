import React from 'react'
import { ReactComponent as AddIcon } from 'core/assets/images/icones/addIconePlain.svg'

export const AddButton: React.FC<{
  onClick: () => void
  label: string
  classname?: string
}> = ({ onClick, label, classname = '' }) => {
  return (
    <div
      className={`btn-without-bg flex flex-row items-center w-fit ${classname}`}
      onClick={onClick}
    >
      <AddIcon className="text-white dark:text-black" />
      <span className="ml-10px">{label}</span>
    </div>
  )
}
