import Loader from 'core/components/common/Loader'
import React, { useContext, useEffect, useState } from 'react'
import {
  IKeycloakUtilisateur,
  IKeycloakUtilisateurColumn,
} from 'core/interfaces/User'
import { UserContext } from 'core/contexts/UserContext'
import { useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { useGetAllKeycloakUsersQuery } from 'lauria/api/keycloakUtilisateurs'
import { ProfilesList } from 'lauria/components/profiles/ProfilesList'
import { ProfilesListMobile } from 'lauria/components/profiles/ProfilesListMobile'
import { useGetAllTagsByClientAuthorizedQuery } from 'core/api/tag'
import { useTranslation } from 'react-i18next'
import { skipToken } from '@reduxjs/toolkit/query'

export const Profiles = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchQuery, setSearchQuery] = useState<string>('')

  const {
    data: users,
    isLoading,
    isFetching,
  } = useGetAllKeycloakUsersQuery({
    search: searchQuery,
    page: 1,
  })

  // @todo : filtrer par idClient
  const {
    user: currentUser,
    isAdmin: isCurrentUserAdmin,
    isSuperAdmin: isCurrentUserSuperAdmin,
  } = useContext(UserContext)

  const idClient = currentUser?.idClient
  const { data: tags, isLoading: isLoadingTags } =
    useGetAllTagsByClientAuthorizedQuery(idClient ? { idClient } : skipToken)

  const [profiles, setProfiles] = React.useState<IKeycloakUtilisateurColumn[]>(
    []
  )

  useEffect(() => {
    if (tags && users) {
      const tagsMap = new Map<number, string>(
        tags.map((tag) => [tag.id, tag.nomTag])
      )

      setProfiles(
        users.map((user: IKeycloakUtilisateur) => ({
          id: user.id,
          email: user.email,
          nom: user.lastName,
          prenom: user.firstName,
          tagsUtilisateur: (user?.tagsUtilisateur ?? [])
            .map((tagId) => tagsMap.get(tagId) || '')
            .filter((tag) => tag !== '')
            .sort(),
          tagsAdministrateur: (user?.tagsAdministrateur ?? [])
            .map((tagId) => tagsMap.get(tagId) || '')
            .filter((tag) => tag !== '')
            .sort(),
        }))
      )
    }
  }, [tags, users])

  if (!isCurrentUserAdmin && !isCurrentUserSuperAdmin) {
    navigate(ROUTE_PATHS.DASHBOARD)
  }
  if (isLoading || isLoadingTags) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }
  return (
    <>
      <div className="profiles-list-wrapper md:flex hidden">
        <div className="profile-search">
          <input
            type="search"
            placeholder={t('profiles.search')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border border-gray-300 p-2 rounded-[5px] w-[400px] h-[50px] text-xl mr-[20px]"
          />

          <button
            onClick={() => setSearchQuery(searchTerm)}
            className="btn default"
            disabled={isLoading || isFetching}
          >
            {t('profiles.searchButton')}
            {(isLoading || isFetching) && (
              <span className="button-loader ml-2 align-middle"></span>
            )}
          </button>
        </div>

        <ProfilesList users={profiles} />
      </div>
      <div className="profiles-list-wrapper flex md:hidden">
        <ProfilesListMobile users={profiles} />
      </div>
    </>
  )
}
