import { useDispatch } from 'react-redux'
import { usePostFeedbackMutation } from 'core/api/feedback'
import { IFeedbackBody } from 'core/interfaces/Feedback'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { useNavigate } from 'react-router'
import { useContext } from 'react'
import { UserContext } from 'core/contexts/UserContext'

export const useHandleRateMessage = (
  application: string | null,
  messageId: number
) => {
  const [postFeedback, { isLoading }] = usePostFeedbackMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user: currentUser } = useContext(UserContext)

  const handleRateMessage = (rating: number) => {
    const idClient = currentUser?.idClient

    if (!idClient || application === null || messageId === 0) {
      return
    }

    const feedback: IFeedbackBody = {
      idClient,
      application,
      message_id: messageId,
      note: rating,
    }
    postFeedback(feedback)
      .unwrap()
      .then(() => {
        dispatch({
          type: 'conversation/addFeedback',
          payload: {
            application,
            feedback: rating,
            id: messageId,
          },
        })
      })
      .catch((error) => {
        console.error(error)
        navigate(ROUTE_PATHS.ERROR)
      })
  }
  return { handleRateMessage, isLoading }
}
