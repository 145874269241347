import { IService } from 'core/interfaces/Service'
import React, { useContext, useState } from 'react'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import { getIconeInfo } from 'core/utils/service'
import { FieldError } from 'react-hook-form'

export const ServiceCard: React.FC<{
  service: IService
  isSelected: boolean
  onClick: (service: IService) => void
  error: FieldError | undefined
}> = ({ service, isSelected, onClick, error }) => {
  const { isDarkTheme } = useContext(DarkThemeContext)
  const [isHovered, setIsHovered] = useState(false)
  const { color, svgPathDark, svgPath } = getIconeInfo(service)

  return (
    <div
      onClick={() => onClick(service)}
      className="card service-card"
      style={{
        backgroundColor:
          isHovered || isSelected
            ? `${color}20`
            : isDarkTheme
              ? '#1C1C1C'
              : 'white',
        borderColor: error
          ? 'red'
          : isHovered || isSelected
            ? color
            : isDarkTheme
              ? '#4D5154'
              : '#EAEBEE',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        className="mt-10px"
        src={isDarkTheme ? svgPathDark : svgPath}
        alt="Banner Icon"
      />
      <h3
        className="service-card-title"
        style={{ color: isHovered || isSelected ? color : 'inherit' }}
      >
        {service.nomService}
      </h3>
    </div>
  )
}
