import { ApplicationNavigation } from 'lauria/components/dashboard/ApplicationNavigation'
import { ApplicationForm } from 'lauria/components/applications/ApplicationForm'
import React from 'react'

export const ApplicationEdition: React.FC = () => {
  return (
    <div className="application-edition-page">
      <ApplicationNavigation />
      <ApplicationForm />
    </div>
  )
}
