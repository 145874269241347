import React, { useState } from 'react'
import { ReactComponent as SendIcon } from 'core/assets/images/icones/sendIcon.svg'
import { useTranslation } from 'react-i18next'

export const MessageInput: React.FC<{
  placeholderText: string
  setMessage: (input: string) => void
  onSend: (message: string) => void
  withLabel?: boolean
}> = ({ placeholderText, setMessage, onSend, withLabel = true }) => {
  const { t } = useTranslation()
  const [input, setInput] = useState('')
  const [placeholder, setPlaceholder] = useState(placeholderText)
  const handleFocus = () => {
    setPlaceholder('')
  }
  const handleBlur = () => {
    setPlaceholder(placeholderText)
  }
  const handleSend = () => {
    if (input.trim() !== '') {
      setMessage(input)
      onSend(input)
      setInput('')
    }
  }
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (e.ctrlKey || e.shiftKey) {
        e.preventDefault()
        setInput((prev) => prev + '\n')
      } else {
        e.preventDefault()
        handleSend()
      }
    }
  }

  const isDisabled = input.trim() === ''

  return (
    <div className="w-full bg-gray-1075 rounded-[15px] p-[10px]">
      <div className="flex flex-row w-full justify-center">
        {withLabel && (
          <div className="md:block hidden mr-[20px] pt-[5px]">
            {t('iastart.demande')}
          </div>
        )}
        <textarea
          value={input}
          className="message-input"
          onChange={(e) => setInput(e.target.value)}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
        <button
          onClick={handleSend}
          disabled={isDisabled}
          className={`${isDisabled ? 'text-gray-1200 dark:text-gray-1250' : 'btn-send-message'} btn-send-wrapper`}
        >
          <div className="uppercase mb-[5px] font-medium">
            {t('iastart.envoyer')}
          </div>
          <SendIcon />
        </button>
      </div>
    </div>
  )
}
