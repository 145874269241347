import { useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { useTranslation } from 'react-i18next'
import { useGetAllTagsByClientAuthorizedQuery } from 'core/api/tag'
import {
  useGetKeycloakUserQuery,
  useUpdateKeycloakUserMutation,
} from 'lauria/api/keycloakUtilisateurs'
import { skipToken } from '@reduxjs/toolkit/query'
import { ITag } from 'core/interfaces/Tag'
import {
  ROLE_ADMIN_VALUE,
  ROLE_DESACTIVE_VALUE,
  ROLE_USER_VALUE,
} from 'core/constants/role'
import { ITagUser } from 'core/interfaces/User'
import { toast } from 'core/components/common/Toast'
import { UserContext } from 'core/contexts/UserContext'

export type ProfileForm = {
  roles: { idTag: number; idRole: number }[]
}

export const useEditProfile = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { user: currentUser, isSuperAdmin: isCurrentUserSuperadmin } =
    useContext(UserContext)

  const { idProfile } = useParams<{ idProfile: string }>()

  const { data: user, isLoading: isLoadingUser } = useGetKeycloakUserQuery(
    idProfile ? { id: idProfile } : skipToken
  )

  const [currentUserTagAdmin, setCurrentUserTagAdmin] = useState<ITag[]>([])

  const idClient = currentUser?.idClient
  const { data: tags, isLoading: isLoadingTags } =
    useGetAllTagsByClientAuthorizedQuery(idClient ? { idClient } : skipToken)

  const [updateKeycloakUser] = useUpdateKeycloakUserMutation()

  const { handleSubmit, control, reset } = useForm<ProfileForm>({
    defaultValues: {
      roles: [],
    },
  })
  const {
    fields: roleFields,
    append,
    update: updateRole,
  } = useFieldArray({
    control,
    name: 'roles',
  })

  useEffect(() => {
    if (!tags || !user) {
      return
    }

    if (isCurrentUserSuperadmin) {
      setCurrentUserTagAdmin(tags?.filter((tag: ITag) => tag.actif))
    } else {
      setCurrentUserTagAdmin(
        (currentUser?.tagsAdministrateur || [])
          .map((tagId) => tags.find((tag) => tag.id === tagId))
          .filter((tag): tag is ITag => !!tag)
      )
    }
  }, [isCurrentUserSuperadmin, tags, user, currentUser])

  useEffect(() => {
    reset({
      roles: [],
    })
  }, [tags])

  useEffect(() => {
    currentUserTagAdmin?.forEach((tag: ITagUser) => {
      if (tag) {
        const existingIndex = roleFields.findIndex(
          (field) => field.idTag === tag.id
        )
        if (existingIndex === -1) {
          append({ idTag: tag.id, idRole: getUserRole(tag.id) })
        } else {
          updateRole(existingIndex, {
            idTag: tag.id,
            idRole: getUserRole(tag.id),
          })
        }
      }
    })
  }, [currentUserTagAdmin])

  const getUserRole = (tagId: number): number => {
    if (user?.tagsUtilisateur?.includes(tagId)) {
      return ROLE_USER_VALUE
    }

    if (user?.tagsAdministrateur?.includes(tagId)) {
      return ROLE_ADMIN_VALUE
    }

    return ROLE_DESACTIVE_VALUE
  }

  const onSubmit = handleSubmit((data) => {
    if (idProfile) {
      updateKeycloakUser({
        id: idProfile,
        tagsUtilisateur: data.roles
          .filter((role) => role.idRole === ROLE_USER_VALUE)
          .map((role) => role.idTag),
        tagsAdministrateur: data.roles
          .filter((role) => role.idRole === ROLE_ADMIN_VALUE)
          .map((role) => role.idTag),
      })
        .unwrap()
        .then((user) => {
          if (user.id) {
            toast.success(t('profiles.updateSuccess'))
            navigate(generatePath(ROUTE_PATHS.PROFILES))
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  })

  const cancelAction = (event: any) => {
    event.preventDefault()
    navigate(generatePath(ROUTE_PATHS.PROFILES))
  }

  return {
    user,
    onSubmit,
    isLoading: isLoadingUser || isLoadingTags,
    currentUserTagAdmin,
    roleFields,
    updateRole,
    cancelAction,
  }
}
