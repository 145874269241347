import { FieldError, UseFormRegister } from 'react-hook-form'
import { ApplicationForm } from 'lauria/hooks/useEditApplication'
import { FieldLabel } from 'core/components/common/FieldLabel'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FormTextarea } from 'core/components/common/FormTextarea'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import { ReactComponent as IAAvatar } from 'core/assets/images/ia/iaAvatar.svg'
import { ReactComponent as IAAvatarDark } from 'core/assets/images/ia/iaAvatarDark.svg'

export const ApplicationAccrocheInput: React.FC<{
  register: UseFormRegister<ApplicationForm>
  error: FieldError | undefined
}> = ({ register, error }) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)
  return (
    <div className="w-full">
      <div className="flex flex-row h-[45px] items-center">
        <div className="mr-10px md:hidden flex">
          {isDarkTheme ? (
            <IAAvatarDark className="w-[30px] h-[30px]" />
          ) : (
            <IAAvatar className="w-[30px] h-[30px]" />
          )}
        </div>
        <FieldLabel label={t('applications.form.accrocheLabel')} required />
      </div>
      <div className="flex flex-row items-start w-full">
        <div className="mr-10px self-start hidden md:flex ">
          {isDarkTheme ? (
            <IAAvatarDark className="w-[40px] h-[40px]" />
          ) : (
            <IAAvatar className="w-[40px] h-[40px]" />
          )}
        </div>
        <FormTextarea
          fieldName="accroche"
          register={register}
          error={error}
          wrapperClassName="md:w-[790px] w-full md:h-[70px] h-[100px] px-[20px] py-[10px] rounded-[30px] bg-gray-1100 dark:bg-gray-1600"
          inputClassName="w-full form-input mb-[10px] resize-none h-full"
          wrapperErrorClassName="h-[30px]"
          placeholder={t('applications.form.accrochePlaceholder')}
        />
      </div>
    </div>
  )
}
