import { IKpiRequest, IKpiResponse, IMetric } from 'core/interfaces/Kpi'
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  format,
  intervalToDuration,
  isBefore,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns'
import { PERIOD } from 'core/constants/period'

export const usePromptKpi = () => {
  const transformData = (
    data: Record<string, IKpiResponse[]>,
    valueType: string,
    metric: IMetric,
    period: string,
    start: Date,
    end: Date,
    globalData: IKpiResponse[]
  ) => {
    const result: Record<string, any> = {}
    const isShortPeriod = period === PERIOD.HEBDO || period === PERIOD.MENSUELLE
    const keyFormatter = (item: IKpiResponse) =>
      isShortPeriod ? item.date : item.annee_mois
    Object.entries(data).forEach(([promptId, values]) => {
      values.forEach((item) => {
        const key = keyFormatter(item)
        if (key) {
          if (!result[key]) {
            result[key] = { date: key }
          }
          const rawValue =
            item[
              valueType === 'TOTAL'
                ? (metric.totalField as keyof IKpiResponse)
                : (metric.moyenneField as keyof IKpiResponse)
            ]
          result[key][promptId] =
            typeof rawValue === 'number'
              ? rawValue
              : parseFloat(rawValue as string) || 0
          result[key].total = 0
          result[key].average = 0
        }
      })
    })
    globalData.forEach((item) => {
      const key = keyFormatter(item)
      if (key) {
        if (!result[key]) {
          result[key] = { date: key }
        }

        const totalRaw = item[metric.totalField as keyof IKpiResponse]
        const averageRaw = item[metric.moyenneField as keyof IKpiResponse]
        result[key].total =
          typeof totalRaw === 'number'
            ? totalRaw
            : parseFloat(totalRaw as string) || 0

        result[key].average =
          typeof averageRaw === 'number'
            ? averageRaw
            : parseFloat(averageRaw as string) || 0
      }
    })

    const dateRange: string[] = []
    let currentDate = new Date(start)

    while (
      isBefore(currentDate, end) ||
      currentDate.getTime() === end.getTime()
    ) {
      const formattedDate = format(
        currentDate,
        isShortPeriod ? 'yyyyMMdd' : 'yyyyMM'
      )
      dateRange.push(formattedDate)
      currentDate = isShortPeriod
        ? addDays(currentDate, 1)
        : addMonths(currentDate, 1)
    }

    dateRange.forEach((dateKey) => {
      if (!result[dateKey]) {
        result[dateKey] = {
          date: dateKey,
          total: 0,
          average: 0,
        }
      }
    })

    return dateRange.map((dateKey) => ({
      ...result[dateKey],
      date: format(
        new Date(
          parseInt(dateKey.substring(0, 4)),
          parseInt(dateKey.substring(4, 6)) - 1,
          isShortPeriod ? parseInt(dateKey.substring(6)) : 1
        ),
        isShortPeriod ? 'dd/MM' : 'MM/yyyy'
      ),
    }))
  }

  const checkIfEmpty = (data: Record<string, IKpiResponse[]>): boolean => {
    if (Object.keys(data)?.length === 0) {
      return true
    }
    return Object.values(data).every((array) => array.length === 0)
  }

  const getSumByField = (
    data: Record<string, IKpiResponse[]>,
    fieldName: string,
    totalField?: string
  ): number => {
    return Object.values(data)
      .flat()
      .reduce((sum, item) => {
        const value = item[fieldName as keyof IKpiResponse]
        const numericValue =
          typeof value === 'number' ? value : parseFloat(value ?? '0')
        if (totalField) {
          const total = item[totalField as keyof IKpiResponse]
          const numericTotal =
            typeof total === 'number' ? total : parseFloat(total ?? '0')
          return sum + numericValue * numericTotal
        }
        return sum + numericValue
      }, 0)
  }

  const getBodyRequest = (
    idApplication: string,
    selectedPrompts: number[],
    period: string,
    start: Date,
    end: Date,
    isPreviousPeriod = false
  ): IKpiRequest => {
    let startDate = start
    let endDate = end

    if (isPreviousPeriod) {
      if (period === PERIOD.HEBDO) {
        const lastWeek = subWeeks(start, 1)
        startDate = startOfWeek(lastWeek, { weekStartsOn: 1 })
        endDate = endOfWeek(lastWeek, { weekStartsOn: 1 })
      } else if (period === PERIOD.MENSUELLE) {
        const lastMonth = subMonths(start, 1)
        startDate = startOfMonth(lastMonth)
        endDate = endOfMonth(lastMonth)
      } else if (period === PERIOD.SEMESTRIELLE) {
        const lastSixMonths = subMonths(start, 6)
        startDate = startOfMonth(lastSixMonths)
        endDate = endOfMonth(subMonths(end, 6))
      } else if (period === PERIOD.ANNUELLE) {
        const lastYear = subYears(start, 1)
        startDate = startOfMonth(lastYear)
        endDate = endOfMonth(subMonths(end, 12))
      }
    }

    return {
      idApplication: idApplication,
      idPrompts: selectedPrompts.map((id) => id.toString()),
      type:
        period === PERIOD.HEBDO || period === PERIOD.MENSUELLE
          ? 'JOUR'
          : 'MOIS',
      dateDebut:
        period === PERIOD.HEBDO || period === PERIOD.MENSUELLE
          ? format(startDate, 'yyyyMMdd')
          : format(startDate, 'yyyyMM'),
      dateFin:
        period === PERIOD.HEBDO || period === PERIOD.MENSUELLE
          ? format(endDate, 'yyyyMMdd')
          : format(endDate, 'yyyyMM'),
    }
  }
  const formattedTime = (milliseconds: number): string => {
    const duration = intervalToDuration({ start: 0, end: milliseconds })

    const hours = duration.hours || 0
    const minutes = duration.minutes || 0
    const secs = duration.seconds || 0

    const timeParts = []

    if (hours > 0) timeParts.push(`${hours}h`)
    if (minutes > 0) timeParts.push(`${minutes}m`)
    if (secs > 0 && hours === 0) timeParts.push(`${secs}s`)

    return timeParts.join(' ')
  }
  return {
    transformData,
    checkIfEmpty,
    getSumByField,
    getBodyRequest,
    formattedTime,
  }
}
