import { ITag } from 'core/interfaces/Tag'
import { ReactComponent as EditIcon } from 'core/assets/images/icones/edit.svg'
import { ReactComponent as EditIconDark } from 'core/assets/images/icones/editDark.svg'
import { ReactComponent as DeleteIconDark } from 'core/assets/images/icones/supprimerIconedark.svg'
import { ReactComponent as DeleteIcon } from 'core/assets/images/icones/supprimerIcon.svg'
import { useTranslation } from 'react-i18next'
import React, { useContext } from 'react'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'

export const TagsOptions: React.FC<{
  tags: ITag[]
  displayTagApps: (tag: ITag) => void
  currentTag: ITag | null
  handleEdit: (tag: ITag) => void
  handleDelete: (tag: ITag) => void
}> = ({ tags, displayTagApps, currentTag, handleEdit, handleDelete }) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <>
      <div className="md:w-[370px] w-full tag-menu">
        <div className="tags-options-list-wrapper">
          <div className="tags-options-list">
            {tags.map((tag, index) => {
              return (
                <div
                  key={index}
                  className={`tags-option ${tag.id === currentTag?.id ? 'selected' : ''}`}
                >
                  <div
                    className={`tag-name ${tag.id === currentTag?.id ? 'selected' : ''}`}
                    onClick={() => displayTagApps(tag)}
                  >
                    <div className="flex-grow">
                      {tag.id && (
                        <>
                          <span className="font-medium">{tag.nomTag}</span>
                          <br />
                        </>
                      )}
                      <span className="font-light">
                        {t('tags.application', {
                          count: tag.applicationsCount,
                        })}
                      </span>
                      {!tag.id && (
                        <>
                          <br />
                          <span className="font-light">{t('tags.noTags')}</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex cursor-pointer text-white dark:text-blue-1350">
                    {tag.id &&
                      (isDarkTheme ? (
                        <EditIconDark onClick={() => handleEdit(tag)} />
                      ) : (
                        <EditIcon onClick={() => handleEdit(tag)} />
                      ))}
                  </div>
                  <div className="flex hover:cursor-pointer text-red-1025 hover:text-red-1250 dark:text-gray-1350 dark:hover:text-gray-1250">
                    {tag.id &&
                      (isDarkTheme ? (
                        <DeleteIconDark
                          className="w-[30px]"
                          onClick={() => handleDelete(tag)}
                        />
                      ) : (
                        <DeleteIcon
                          className="w-[30px]"
                          onClick={() => handleDelete(tag)}
                        />
                      ))}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
