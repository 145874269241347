import { generatePath, useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import React, { useContext, useEffect, useState } from 'react'
import { resetAllConversations } from 'iastart/store/slices/conversationSlice'
import { UserContext } from 'core/contexts/UserContext'
import { useGetAllApplicationsByClientQuery } from 'core/api/application'
import { skipToken } from '@reduxjs/toolkit/query'
import { getLastApplications } from 'core/utils/service'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { useGetAllTagsByClientQuery } from 'core/api/tag'
import { IApplication } from 'core/interfaces/Application'
import { ITag } from 'core/interfaces/Tag'
import { useTranslation } from 'react-i18next'

export const useAppListIastart = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { getClientId } = useContext(UserContext)
  const idClient = getClientId()

  const [lastApplications, setLastApplications] = useState<IApplication[]>([])
  const [applicationsFiltered, setApplicationsFiltered] = useState<
    IApplication[]
  >([])
  const [tags, setTags] = useState<ITag[]>([])
  const [selected, setSelected] = useState<number>(0)

  const { data: applicationsDatas, isLoading: isLoadingApplications } =
    useGetAllApplicationsByClientQuery(
      idClient ? { idClient, published: true } : skipToken
    )
  const { data: tagsData, isLoading: isLoadingTags } =
    useGetAllTagsByClientQuery(idClient ? { idClient } : skipToken)

  useEffect(() => {
    dispatch(resetAllConversations())
  }, [dispatch])

  useEffect(() => {
    if (applicationsDatas) {
      setLastApplications(getLastApplications(applicationsDatas))
    }
  }, [applicationsDatas])

  useEffect(() => {
    if (applicationsDatas) {
      const filtered = applicationsDatas.filter(
        (app) =>
          !lastApplications.some((lastApp) => lastApp.id === app.id) &&
          (selected === 0 || app.tags?.some((tag) => tag.id === selected))
      )
      setApplicationsFiltered(filtered)
    }
  }, [applicationsDatas, lastApplications, selected])

  useEffect(() => {
    if (tagsData) {
      setTags([
        { id: 0, nomTag: t('iastart.allApps'), actif: true },
        ...tagsData,
      ])
    }
  }, [tagsData, t])

  const handleTagClick = (tagId: number) => setSelected(tagId)

  const handleClick = (app: IApplication) => {
    console.log(app)
    navigate(generatePath(ROUTE_PATHS.IASTART_APP, { app: app.nomTechnique }))
  }

  return {
    lastApplications,
    applicationsFiltered,
    applications: applicationsDatas,
    isLoading: isLoadingApplications || isLoadingTags,
    handleClick,
    tags,
    selected,
    handleTagClick,
  }
}
