import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Layout } from 'core/pages/Layout'
import 'core/assets/styles/style.css'
import { UseKeycloakService } from 'core/packages/KeycloakProvider'
import Loader from 'core/components/common/Loader'
import { Error404 } from 'core/components/error/Error404'
import ErrorHandler from 'core/components/error/ErrorHandler'
import { Error500 } from 'core/components/error/Error500'
import { BACKOFFICE_PATH, ROUTE_PATHS } from 'core/constants/routePath'
import { Dashboard } from 'lauria/pages/Dashboard'
import { PromptList } from 'lauria/pages/prompts/PromptList'
import { PromptEdition } from 'lauria/pages/prompts/PromptEdition'
import { LayoutApplication } from 'lauria/pages/applications/LayoutApplication'
import { Tags } from 'lauria/pages/Tags'
import { Prompts } from 'lauria/pages/prompts/Prompts'
import { Kpi } from 'lauria/pages/prompts/Kpi'
import { Profiles } from 'lauria/pages/profiles/Profiles'
import { ProfileEdition } from 'lauria/pages/profiles/ProfileEdition'
import store, { persistor } from 'core/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { IaStart } from 'iastart/pages/IaStart'
import { IaStartApps } from 'iastart/pages/IaStartApps'
import { IKeycloakUtilisateur } from 'core/interfaces/User'
import { UserContext } from 'core/contexts/UserContext'
import { ApplicationEdition } from 'lauria/pages/applications/ApplicationEdition'
import { KEYCLOAK_ROLE_SUPERADMIN } from 'core/constants/role'
import { useGetAllClientsQuery, useGetClientQuery } from 'lauria/api/client'
import { ModelesLLMProvider } from 'lauria/contexts/ModelesLLMContext'

function App() {
  const kc = UseKeycloakService()
  const { setCurrentUser, setClients, updateUser } = useContext(UserContext)
  const isLoading = !kc.isInitialized()

  useEffect(() => {
    if (kc.isInitialized()) {
      const parsedToken = kc.getTokenParsed()

      const availableClients: number[] = (
        parsedToken?.lauria?.['idClient'] ?? []
      ).map(Number)

      let selectedClientId = availableClients[0]

      const currentUser: IKeycloakUtilisateur = {
        id: parsedToken?.sub,
        email: parsedToken?.email,
        firstName: parsedToken?.given_name,
        lastName: parsedToken?.family_name,
        idClient: selectedClientId,
        tagsUtilisateur: (parsedToken?.lauria?.['tags-utilisateur'] ?? []).map(
          Number
        ),
        tagsAdministrateur: (
          parsedToken?.lauria?.['tags-administrateur'] ?? []
        ).map(Number),
      }

      setCurrentUser(currentUser)
    }
  }, [kc.isInitialized()])

  const isSuperAdmin = kc.checkRole(KEYCLOAK_ROLE_SUPERADMIN)

  const { data: allClientsData, isFetching: isAllClientsLoading } =
    useGetAllClientsQuery(undefined, {
      skip: !kc.isInitialized() || !isSuperAdmin,
    })

  const firstClientId = useContext(UserContext)?.user?.idClient?.toString()
  const { data: singleClientData, isFetching: isSingleClientLoading } =
    useGetClientQuery(
      { id: firstClientId! },
      { skip: !kc.isInitialized() || isSuperAdmin || !firstClientId }
    )

  useEffect(() => {
    if (isSuperAdmin && allClientsData) {
      setClients(allClientsData)

      const forcedSelectedClient = allClientsData.find((client) => {
        return client.nomClient === 'L2D'
      })

      if (forcedSelectedClient) {
        updateUser({ idClient: forcedSelectedClient.id })
      }
    } else if (!isSuperAdmin && singleClientData) {
      setClients([singleClientData])
    }
  }, [allClientsData, singleClientData, isSuperAdmin, setClients])

  const {
    APPLICATION_CREATION,
    APPLICATION_EDITION,
    PROFILES,
    PROFILES_EDIT,
    PROMPTS,
    PROMPT_CREATE,
    PROMPT_EDIT,
    ERROR,
    TAG_ADMIN,
    KPI,
    IASTART,
    IASTART_APP,
  } = ROUTE_PATHS
  if (isLoading || isAllClientsLoading || isSingleClientLoading) {
    return (
      <Loader center className="h-screen flex items-center justify-center" />
    )
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorHandler>
          <ModelesLLMProvider>
            <BrowserRouter>
              <Routes>
                <Route path={BACKOFFICE_PATH}>
                  <Route path="" element={<Layout displayProfileIcone />}>
                    <Route path="" element={<Dashboard />} />
                    <Route path={TAG_ADMIN} element={<Tags />} />
                    <Route path={PROFILES} element={<Profiles />} />
                    <Route path={PROFILES_EDIT} element={<ProfileEdition />} />
                    <Route
                      path={APPLICATION_CREATION}
                      element={<ApplicationEdition />}
                    />
                    <Route
                      path={APPLICATION_EDITION}
                      element={<ApplicationEdition />}
                    />
                  </Route>
                  <Route path="" element={<LayoutApplication />}>
                    <Route path={PROMPTS} element={<Prompts />}>
                      <Route index element={<PromptList />} />
                      <Route path={KPI} element={<Kpi />} />
                    </Route>
                    <Route path={PROMPT_EDIT} element={<PromptEdition />} />
                    <Route path={PROMPT_CREATE} element={<PromptEdition />} />
                  </Route>
                </Route>
                <Route path="" element={<Layout />}>
                  <Route path={IASTART} element={<IaStart />} />
                  <Route path={IASTART_APP} element={<IaStartApps />} />
                </Route>
                <Route path="" element={<Layout itemsCenter />}>
                  <Route path={ERROR} element={<Error500 />} />
                  <Route path="*" element={<Error404 />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </ModelesLLMProvider>
        </ErrorHandler>
      </PersistGate>
    </Provider>
  )
}

export default App
