import React, { useContext } from 'react'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import { IApplication } from 'core/interfaces/Application'
import { getIconeInfo } from 'core/utils/service'

interface PromptHeaderProps {
  application: IApplication
}

export const ApplicationHeader: React.FC<PromptHeaderProps> = ({
  application,
}) => {
  const { isDarkTheme } = useContext(DarkThemeContext)
  const { svgPathDark, svgPath } = getIconeInfo(application.service)
  const { nomApplication, service, tags } = application

  return (
    <div className="prompt-header">
      <img src={isDarkTheme ? svgPathDark : svgPath} alt="Banner Icon" />
      <div className="flex flex-col ml-10px">
        <div className="text-gray-1250 uppercase text-[11px]">
          {service?.nomService}
        </div>
        <div className="font-medium">{nomApplication}</div>
      </div>
      <div className="hidden md:flex md:flex-row md:h-full md:items-center">
        <div className="tags">
          {tags &&
            tags.map((tag) => (
              <div className="tag-button" key={tag.id}>
                {tag.nomTag}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
