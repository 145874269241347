import ModalWrapper from 'core/components/common/ModalWrapper'
import { FC, SetStateAction } from 'react'
import { ModalHeader } from 'core/components/common/ModalHeader'
import { useTranslation } from 'react-i18next'
import { ModalBody } from 'core/components/common/ModalBody'
import { IApplication } from 'core/interfaces/Application'
import { FormPublicationApplicationModalContent } from 'lauria/components/applications/FormPublicationApplicationModalContent'

export const FormPublicationApplicationModal: FC<{
  open: boolean
  setOpen: (value: SetStateAction<boolean>) => void
  application: IApplication
}> = ({ open, setOpen, application }) => {
  const { t } = useTranslation()

  const hide = (event: any) => {
    event.preventDefault()
    setOpen(false)
  }

  return (
    <ModalWrapper
      classNames={{ modal: 'modal modal-publication' }}
      onClose={() => setOpen(false)}
      open={open}
      showCloseIcon={false}
    >
      <ModalHeader>{t('applications.form.publication.title')}</ModalHeader>
      <ModalBody>
        <FormPublicationApplicationModalContent
          cancelAction={hide}
          application={application}
          setModalOpen={setOpen}
        />
      </ModalBody>
    </ModalWrapper>
  )
}
