import { ReactComponent as Avatar } from 'lauria/assets/images/common/avatar.svg'
import { ReactComponent as AvatarDark } from 'lauria/assets/images/common/avatarDark.svg'
import { FC } from 'react'
import { useUserCard } from 'lauria/hooks/useUserCard'

export const UserCard: FC<{ username: string }> = ({ username }) => {
  const {
    isDarkTheme,
    clients,
    selectedClient,
    handleClientChange,
    isSuperAdmin,
  } = useUserCard()

  return (
    <div className="card-user relative">
      <div>{isDarkTheme ? <AvatarDark /> : <Avatar />}</div>
      <div className="username-wrapper">
        <div className="username">{username}</div>
        <div>
          <select
            className="border rounded p-1 mt-2"
            value={selectedClient?.id ?? ''}
            onChange={handleClientChange}
            disabled={!isSuperAdmin}
          >
            {clients.map((client) => (
              <option key={client.id} value={client.id}>
                {client.nomClient}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}
