import { ReactComponent as SmallLogoBack } from 'core/assets/images/general/logoLauriaBackMini.svg'
import { ReactComponent as SmallLogo } from 'core/assets/images/general/logoLauriaMini.svg'
import { NavLink } from 'react-router-dom'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { generatePath } from 'react-router'
import { useTranslation } from 'react-i18next'
import React from 'react'

export const SmallLogoLink: React.FC<{ backoffice?: boolean }> = ({
  backoffice = false,
}) => {
  const { t } = useTranslation()
  return (
    <NavLink
      to={generatePath(
        backoffice ? ROUTE_PATHS.DASHBOARD : ROUTE_PATHS.IASTART
      )}
      className="flex flex-row w-auto items-center text-gray-1325 ml-[30px]"
    >
      {backoffice ? (
        <SmallLogoBack className="h-[45px] w-[45px]" />
      ) : (
        <SmallLogo className="h-[45px] w-[45px]" />
      )}
      <div className="flex flex-col ml-10px">
        <div className="text-[13px] font-medium">
          {t('applications.homeTitle')}
        </div>

        <div className="text-[11px] underline">{t('applications.goHome')}</div>
      </div>
    </NavLink>
  )
}
