import React from 'react'
import { IPromptDetail } from 'core/interfaces/Prompt'
import { ReactComponent as PromptIcon } from 'lauria/assets/images/prompts/promptIcon.svg'
import { PromptStateTag } from 'lauria/components/prompts/PromptStateTag'
import { NbDocIcon } from 'lauria/components/common/NbDocIcon'
import { PromptModeleTag } from 'lauria/components/prompts/PromptModeleTag'
import { useTranslation } from 'react-i18next'

export const PromptCard: React.FC<{
  prompt: IPromptDetail
  selected: boolean
  setSelectedPrompt: (id: number) => void
}> = ({ prompt, selected, setSelectedPrompt }) => {
  const { t } = useTranslation()
  return (
    <div
      className={`prompt-card-list-item group ${selected ? 'active' : ''}`}
      onClick={() => {
        setSelectedPrompt(prompt.id)
      }}
    >
      <div className="prompt-card-list-item-header-wrapper">
        <div className="prompt-card-list-item-header-icon group-hover:border-blue-1350">
          <PromptIcon
            className={`h-[30px] group-hover:text-blue-1350 ${selected ? 'text-blue-1350' : ''}`}
          />
        </div>
        <div
          className={`prompt-card-list-item-header-title group-hover:border-blue-1350 group-hover:text-blue-1350 ${selected ? 'text-blue-1350' : ''}`}
        >
          <span className="truncate w-full block">{prompt.nomPrompt}</span>
        </div>
      </div>
      <div className="prompt-card-list-item-content justify-between">
        <div className="flex flex-row items-center text-gray-1250">
          {prompt.note ? prompt.note : t('prompts.diffusion.nonNote')}&nbsp;
          <span className="text-[16px]">{prompt.note ? '★' : '☆'}</span>
        </div>
        <div className="flex flex-row items-center gap-x-[5px]">
          <PromptModeleTag modele={prompt.modele} />
          {prompt.documentsCount > 0 && (
            <NbDocIcon nbDoc={prompt.documentsCount} />
          )}
          <PromptStateTag actif={prompt.actif} />
        </div>
      </div>
    </div>
  )
}
