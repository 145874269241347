import { useForm } from 'react-hook-form'
import { ITag } from 'core/interfaces/Tag'
import { useCreateTagMutation, useUpdateTagMutation } from 'core/api/tag'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { UserContext } from 'core/contexts/UserContext'
import { toast } from 'core/components/common/Toast'
import { ERROR_DUPLICATE_NOM_TAG } from 'core/constants/error'

export type TagForm = {
  intitule: string
}
export const useEditTag = (
  setModalOpen?: (value: boolean) => void,
  tag?: ITag | null
) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isValid },
  } = useForm<TagForm>({
    defaultValues: {
      intitule: tag?.nomTag || '',
    },
  })
  const { user: currentUser } = useContext(UserContext)

  const [createTag, { isLoading: isLoadingCreate }] = useCreateTagMutation()
  const [updateTag, { isLoading: isLoadingUpdate }] = useUpdateTagMutation()

  const catchError = (error: any) => {
    if (error.data.status === 409) {
      if (error.data?.detail === ERROR_DUPLICATE_NOM_TAG) {
        setError('intitule', {
          type: 'custom',
          message: t('tags.form.errors.duplicateNomTag'),
        })
      }
    }
  }

  const onSubmit = handleSubmit((data) => {
    if (tag) {
      updateTag({
        idTag: tag.id,
        nomTag: data.intitule,
      })
        .unwrap()
        .then((tag) => {
          if (tag.id) {
            toast.success(t('tags.form.edit.success'))

            if (setModalOpen) {
              setModalOpen(false)
            }
          }
        })
        .catch(catchError)
    } else {
      const idClient = currentUser?.idClient

      if (!idClient) {
        return
      }

      createTag({
        idClient: idClient,
        nomTag: data.intitule,
      })
        .unwrap()
        .then((tag) => {
          if (tag.id) {
            setValue('intitule', '')
            toast.success(t('tags.form.create.success'))

            if (setModalOpen) {
              setModalOpen(false)
            }
          }
        })
        .catch(catchError)
    }
  })

  return {
    register,
    onSubmit,
    setError,
    isValid,
    errors,
    isLoading: isLoadingCreate || isLoadingUpdate,
    setValue,
  }
}
