import { ReactComponent as IAAvatar } from 'core/assets/images/ia/iaAvatar.svg'
import { ReactComponent as IAAvatarDark } from 'core/assets/images/ia/iaAvatarDark.svg'
import React, { useContext } from 'react'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import StarRating from 'core/components/common/StarRating'
import { useTranslation } from 'react-i18next'
import { useHandleRateMessage } from 'core/hooks/useHandleRateMessage'
import Loader from 'core/components/common/Loader'
import { IMessage } from 'core/interfaces/Conversation'

export const IAMessage: React.FC<{
  message: IMessage
  first?: boolean
  isLoading?: boolean
  application?: string
  smallAvatar?: boolean
}> = ({
  message,
  first = false,
  isLoading = false,
  application,
  smallAvatar = false,
}) => {
  const { isDarkTheme } = useContext(DarkThemeContext)
  const { t } = useTranslation()
  const { handleRateMessage, isLoading: isLoadingRate } = useHandleRateMessage(
    application || null,
    message.id ?? 0
  )
  return (
    <div
      className={`ia-message message-container ${first ? 'md:mt-15px' : ''}`}
    >
      <div className="flex flex-row w-full">
        <div className="avatar">
          {isDarkTheme ? (
            <IAAvatarDark
              className={`${smallAvatar ? 'md:w-[40px]' : 'md:w-[60px]'} w-[30px]`}
            />
          ) : (
            <IAAvatar
              className={`${smallAvatar ? 'md:w-[40px]' : 'md:w-[60px]'} w-[30px]`}
            />
          )}
        </div>
        <div className="flex flex-col w-full">
          <div className={`message ${first ? '' : 'mb-10px'}`}>
            <div className="message-content">
              {isLoading ? (
                <div className="dots text-3xl h-[30px]"></div>
              ) : (
                <div className="message-text">{message?.content}</div>
              )}
            </div>
          </div>
          {!first && !isLoading && application && (
            <div className="flex flex-row items-center">
              {!isLoadingRate ? (
                <>
                  <span className="text-blue-1350 mr-10px">
                    {message?.feedback
                      ? t('iastart.merci')
                      : t('iastart.evaluation')}
                  </span>
                  <StarRating
                    initialRate={message?.feedback}
                    onClick={handleRateMessage}
                  />
                </>
              ) : (
                <Loader size="small" />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
