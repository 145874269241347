import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import { UserContext } from 'core/contexts/UserContext'
import { IClient } from 'core/interfaces/User'

export const useUserCard = () => {
  const { isDarkTheme } = useContext(DarkThemeContext)
  const { user, updateUser, clients, isSuperAdmin } = useContext(UserContext)

  const [selectedClient, setSelectedClient] = useState<IClient | undefined>(
    undefined
  )

  useEffect(() => {
    if (clients.length > 0) {
      const defaultClient = clients.find(
        (client) => client.id === user?.idClient
      )
      setSelectedClient(defaultClient)
    }
  }, [clients, user, isSuperAdmin])

  const handleClientChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newClient = clients.find(
      (client) => client.id.toString() === event.target.value
    )
    if (newClient) {
      setSelectedClient(newClient)
      updateUser({ idClient: newClient.id })
    }
  }

  return {
    isDarkTheme,
    // clientsOptions,
    clients,
    selectedClient,
    handleClientChange,
    isSuperAdmin,
  }
}
