import { IKeycloakUtilisateurColumn } from 'core/interfaces/User'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table'
import SortableHeader from 'lauria/components/profiles/SortableHeader'
import { Pagination } from 'lauria/components/profiles/Pagination'
import { generatePath, useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'core/constants/routePath'

export const ProfilesList: React.FC<{
  users: IKeycloakUtilisateurColumn[]
}> = ({ users }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const changePage = (index: number) => {
    table.setPageIndex(index - 1)
  }

  const editProfile = (idProfile: string) => {
    navigate(generatePath(ROUTE_PATHS.PROFILES_EDIT, { idProfile }))
  }
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })
  const columnHelper = createColumnHelper<IKeycloakUtilisateurColumn>()
  const columns = [
    columnHelper.accessor('nom', {
      header: ({ column }) => (
        <SortableHeader label={t('profiles.nom')} column={column} />
      ),
      enableSorting: true,
    }),
    columnHelper.accessor('prenom', {
      header: t('profiles.prenom'),
    }),
    columnHelper.accessor('email', {
      header: t('profiles.email'),
    }),
    columnHelper.accessor('tagsUtilisateur', {
      header: t('profiles.tagsUtilisateur'),
      cell: ({ getValue }) => {
        const tags = getValue() as string[]
        return (
          <div className="tags">
            {tags.map((tag, index) => (
              <span key={index} className="tag">
                {tag}
              </span>
            ))}
          </div>
        )
      },
    }),
    columnHelper.accessor('tagsAdministrateur', {
      header: t('profiles.tagsAdministrateur'),
      cell: ({ getValue }) => {
        const tags = getValue() as string[]
        return (
          <div className="tags">
            {tags.map((tag, index) => (
              <span key={index} className="tag">
                {tag}
              </span>
            ))}
          </div>
        )
      },
    }),
  ]
  const table = useReactTable({
    data: users,
    columns,
    initialState: {
      sorting: [
        {
          id: 'nom',
          desc: false,
        },
      ],
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  })

  return (
    <>
      <table className="table-profile">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="hover:bg-gray-50"
              onClick={() => editProfile(row.original?.id ?? '')}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        nbPage={table.getPageCount()}
        currentPage={table.getState().pagination.pageIndex + 1}
        onChangePage={changePage}
      />
    </>
  )
}
