import { IService } from 'core/interfaces/Service'
import { getIconeInfo } from 'core/utils/service'
import React, { useContext } from 'react'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'

export const ApplicationIcon: React.FC<{
  service: IService | null
  smallSize?: boolean
  withBg?: boolean
}> = ({ service, smallSize = false, withBg = true }) => {
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <div
      className={`app-icon-wrapper ${smallSize ? 'min-w-[70px] h-[70px]' : 'min-w-[115px] h-[115px]'}  ${withBg ? '' : '!bg-transparent'}`}
    >
      {service ? (
        <img
          className={`${smallSize ? 'w-[50px] h-[50px]' : 'w-[60px] h-[60px]'}`}
          src={
            isDarkTheme
              ? getIconeInfo(service).svgPathDark
              : getIconeInfo(service).svgPath
          }
          alt="Banner Icon"
        />
      ) : (
        <div className="app-empty-icon"></div>
      )}
    </div>
  )
}
