import React from 'react'

export const UserMessage: React.FC<{
  message: string
  smallAvatar?: boolean
}> = ({ message, smallAvatar = false }) => {
  return (
    <div className="user-message message-container">
      <div
        className={` ${smallAvatar ? 'md:ml-[60px]' : 'md:ml-[80px]'} ml-[20px] message`}
      >
        <div className="message-text">{message}</div>
      </div>
    </div>
  )
}
