import { IDocument } from 'core/interfaces/Document'
import { useTranslation } from 'react-i18next'
import React, { SetStateAction } from 'react'
import { DocumentCard } from 'core/components/documents/DocumentCard'

export const PromptDocumentList: React.FC<{
  documents: IDocument[]
  newFiles?: File[]
  withDelete?: boolean
  setDocuments?: (value: SetStateAction<IDocument[]>) => void
  setNewFiles?: (value: SetStateAction<File[]>) => void
}> = ({
  documents,
  newFiles,
  withDelete = false,
  setDocuments = () => {},
  setNewFiles = () => {},
}) => {
  const { t } = useTranslation()
  const deleteDoc = (indexToDelete: number) => {
    if (withDelete) {
      const updatedDocuments = documents.filter(
        (doc, index) => index !== indexToDelete
      )
      setDocuments(updatedDocuments)
      if (newFiles) {
        const updatedNewFiles = newFiles?.filter(
          (doc, index) => index !== indexToDelete
        )
        setNewFiles(updatedNewFiles)
      }
    }
  }

  return documents.length > 0 ? (
    <div className="prompt-document-list-content-form">
      {documents.map((doc, index) => (
        <DocumentCard
          filename={doc.nomDocument}
          withDelete={withDelete}
          key={index}
          deleteDoc={() => deleteDoc(index)}
        />
      ))}
    </div>
  ) : (
    <div className="text-gray-1250 h-full flex items-center">
      {t('prompts.documents.noDocuments')}
    </div>
  )
}
