import { Outlet } from 'react-router'
import React, { FC } from 'react'
import Loader from 'core/components/common/Loader'
import { ApplicationHeader } from 'lauria/components/applications/ApplicationHeader'
import { useLayoutApplication } from 'lauria/hooks/useLayoutApplication'
import { DarkModeProvider } from 'core/contexts/DarkThemeContext'
import { DarkThemeToggle } from 'core/components/layout/DarkThemeToggle'
import { CustomToastContainer } from 'core/components/common/Toast'
import { SmallLogoLink } from 'core/components/common/SmallLogoLink'

export const LayoutApplication: FC = () => {
  const { application, isLoading } = useLayoutApplication()

  return (
    <DarkModeProvider>
      <div className="layout-container">
        {application && !isLoading ? (
          <>
            <div className="layout-header">
              <div className="flex-shrink-0">
                <SmallLogoLink backoffice />
              </div>
              <div className="hidden md:flex items-center">
                <ApplicationHeader application={application} />
              </div>
              <div className="flex-shrink-0 pr-[30px] ">
                <DarkThemeToggle />
              </div>
            </div>
            <Outlet />
          </>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <Loader pageCenter />
          </div>
        )}
        <CustomToastContainer />
      </div>
    </DarkModeProvider>
  )
}
