import React, { createContext, useState, useContext } from 'react'
import { IPromptTestConversation } from 'core/interfaces/Ask'

interface PromptConversationContextType {
  conversation: IPromptTestConversation
  setConversation: React.Dispatch<React.SetStateAction<IPromptTestConversation>>
}

const PromptConversationContext = createContext<
  PromptConversationContextType | undefined
>(undefined)

export const PromptConversationProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [conversation, setConversation] = useState<IPromptTestConversation>({
    messages: [],
  })

  return (
    <PromptConversationContext.Provider
      value={{ conversation, setConversation }}
    >
      {children}
    </PromptConversationContext.Provider>
  )
}

export const usePromptConversation = () => {
  const context = useContext(PromptConversationContext)
  if (!context) {
    throw new Error(
      'useConversation must be used within a ConversationProvider'
    )
  }
  return context
}
