import React, { useState } from 'react'
import { ReactComponent as PromptIcon } from 'lauria/assets/images/prompts/promptIcon.svg'
import { ReactComponent as Chevron } from 'core/assets/images/icones/chevronDown.svg'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AppOptionIcon } from 'core/assets/images/icones/appOption.svg'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { generatePath, useNavigate } from 'react-router'
import { PromptSubHeaderProps } from 'lauria/components/prompts/PromptSubHeader'
import { ApplicationHeaderMobile } from 'lauria/components/applications/ApplicationHeaderMobile'

interface IPromptOption {
  key: string
  label: string
  subLabel: string
  subLabelClassname: string
  icon: JSX.Element
  onClick: (option: IPromptOption) => void
}

export const PromptSubHeaderMobile: React.FC<PromptSubHeaderProps> = ({
  application,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [openMenu, setOpenMenu] = useState(false)
  const toggleDropdown = () => {
    setOpenMenu(!openMenu)
  }
  const handleOptionClick = (option: IPromptOption) => {
    setOpenMenu(false)
    option.onClick(option)
  }

  const options: IPromptOption[] = [
    {
      key: 'prompts',
      label: t('prompts.title'),
      subLabel: t('prompts.subtitle'),
      subLabelClassname: '',
      icon: <PromptIcon />,
      onClick: (option) => {
        setSelectedOption(option)
        navigate(
          generatePath(ROUTE_PATHS.PROMPTS, {
            idApplication: application.id,
          })
        )
      },
    },
    {
      key: 'options',
      label: t('prompts.options'),
      subLabel: t('prompts.optionsText'),
      subLabelClassname: '',
      icon: <AppOptionIcon />,
      onClick: () => {
        navigate(
          generatePath(ROUTE_PATHS.APPLICATION_EDITION, {
            idApplication: application.id,
          })
        )
      },
    },
  ]

  const [selectedOption, setSelectedOption] = useState(options[0])
  const otherOptions = options.filter((opt) => opt.key !== selectedOption.key)

  return (
    <div className="prompt-sub-header-mobile">
      <ApplicationHeaderMobile application={application} />
      <div className="menu-wrapper">
        <div
          onClick={toggleDropdown}
          className="flex flex-row items-center justify-between h-full"
        >
          <div className="flex flex-row items-center">
            {React.cloneElement(selectedOption.icon, {
              className: 'w-[30px] h-[30px]',
            })}
            <div className="flex flex-col ml-[15px]">
              <span className="font-medium">{selectedOption.label}</span>
              <span className={selectedOption.subLabelClassname}>
                {selectedOption.subLabel}
              </span>
            </div>
          </div>
          <span>
            <Chevron />
          </span>
        </div>
      </div>
      {openMenu && (
        <div className="dropdown-menu">
          {otherOptions.map((option, index) => (
            <div
              key={index}
              className="flex flex-row items-center my-10px"
              onClick={() => handleOptionClick(option)}
            >
              {React.cloneElement(option.icon, {
                className: 'w-[30px] h-[30px]',
              })}
              <div className="flex flex-col ml-[15px]">
                <span className="font-medium">{option.label}</span>
                <span className={option.subLabelClassname}>
                  {option.subLabel}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
