import { baseApi } from 'core/api/base'
import { generatePath } from 'react-router'
import { ITag } from 'core/interfaces/Tag'

const CLIENT_TAGS_ENDPOINT = '/clients/:idClient/tags'
const CLIENT_TAGS_AUTHORIZED_ENDPOINT = '/clients/:idClient/tags/authorized'

const TAGS_ENDPOINT = '/tags'
const TAG_ENDPOINT = `${TAGS_ENDPOINT}/:idTag`
const TAGS_WITH_APPLICATIONS_ENDPOINT =
  '/clients/:idClient/tags-with-applications'

const tagApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTagsByClient: builder.query<
      ITag[],
      {
        idClient: number
      }
    >({
      query: ({ idClient }) => {
        return generatePath(CLIENT_TAGS_ENDPOINT, {
          idClient: idClient.toString(),
        })
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Tag', id }) as const),
              { type: 'Tag', id: 'LIST' },
            ]
          : [{ type: 'Tag', id: 'LIST' }],
    }),
    getAllTagsByClientAuthorized: builder.query<
      ITag[],
      {
        idClient: number
      }
    >({
      query: ({ idClient }) => {
        return generatePath(CLIENT_TAGS_AUTHORIZED_ENDPOINT, {
          idClient: idClient.toString(),
        })
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Tag', id }) as const),
              { type: 'Tag', id: 'LIST' },
            ]
          : [{ type: 'Tag', id: 'LIST' }],
    }),
    getAllTagsByClientWithApplications: builder.query<
      ITag[],
      { idClient: number }
    >({
      query: ({ idClient }) =>
        generatePath(TAGS_WITH_APPLICATIONS_ENDPOINT, {
          idClient: idClient.toString(),
        }),
      providesTags: (result: ITag[] | undefined) =>
        result
          ? [
              ...result.map(
                ({ id }) => ({ type: 'TagsWithApps', id }) as const
              ),
              { type: 'TagsWithApps', id: 'LIST' },
            ]
          : [{ type: 'TagsWithApps', id: 'LIST' }],
    }),
    createTag: builder.mutation<
      ITag,
      {
        idClient: number
        nomTag: string
      }
    >({
      query: ({ idClient, nomTag }) => ({
        url: generatePath(TAGS_ENDPOINT),
        method: 'POST',
        body: { idClient: idClient, nomTag: nomTag, actif: true },
      }),
      invalidatesTags: (result, error) =>
        error ? [] : [{ type: 'TagsWithApps', id: 'LIST' }],
    }),
    updateTag: builder.mutation<
      ITag,
      {
        nomTag: string
        idTag: number
      }
    >({
      query: ({ nomTag, idTag }) => ({
        url: generatePath(TAG_ENDPOINT, {
          idTag: idTag.toString(),
        }),
        method: 'PATCH',
        body: { nomTag },
      }),
      invalidatesTags: (result, error, { idTag }) =>
        error ? [] : [{ type: 'TagsWithApps', idTag }],
    }),
    deleteTag: builder.mutation<void, number>({
      query(idTag) {
        return {
          url: generatePath(TAG_ENDPOINT, {
            idTag: idTag.toString(),
          }),
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'TagsWithApps', id }],
    }),
  }),
})

export const {
  useGetAllTagsByClientQuery,
  useGetAllTagsByClientAuthorizedQuery,
  useGetAllTagsByClientWithApplicationsQuery,
  useCreateTagMutation,
  useUpdateTagMutation,
  useDeleteTagMutation,
} = tagApi
export { tagApi }
