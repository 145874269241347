import { useEditApplication } from 'lauria/hooks/useEditApplication'
import { ServiceCardList } from 'lauria/components/applications/ServiceCardList'
import { TagSelect } from 'lauria/components/dashboard/TagSelect'
import React from 'react'
import { TagWithDeleteButton } from 'lauria/components/common/TagWithDeleteButton'
import { useTranslation } from 'react-i18next'
import { ApplicationDescriptionCard } from 'lauria/components/applications/ApplicationDescriptionCard'
import { ApplicationAccrocheInput } from 'lauria/components/applications/ApplicationAccrocheInput'
import { FieldLabel } from 'core/components/common/FieldLabel'
import { FormInput } from 'core/components/common/FormInput'

export const ApplicationForm: React.FC = () => {
  const { t } = useTranslation()
  const {
    register,
    errors,
    services,
    state,
    handleSelectService,
    handleTagRemove,
    creation,
    isValid,
    isLoading,
    cancelAction,
    onSubmit,
    handleIntituleBlur,
  } = useEditApplication()

  return (
    <div className="application-edition-content">
      <form className="application-form-wrapper" onSubmit={onSubmit}>
        <div className="flex md:flex-row flex-col w-full">
          {services && (
            <ServiceCardList
              services={services}
              selectedService={state.selectedService}
              handleSelectService={handleSelectService}
              register={register}
              error={errors?.idService}
            />
          )}
          <div className="md:ml-auto">
            <TagSelect
              error={errors?.tags}
              register={register}
              selectedTags={state.selectedTags}
              setSelectedTags={state.setSelectedTags}
              required
            />
            {state.selectedTags.length > 0 && (
              <div className="tags-list w-[300px]">
                {state.selectedTags.map((tag) => (
                  <TagWithDeleteButton
                    key={tag.id}
                    tag={tag}
                    onClick={handleTagRemove}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="flex md:flex-row flex-col w-full">
          <ApplicationDescriptionCard
            serviceSelected={state.selectedService}
            register={register}
            errors={errors}
            handleIntituleBlur={handleIntituleBlur}
          />
          <div className="md:flex md:flex-col md:ml-auto">
            <FieldLabel
              className="h-[45px] w-[300px] flex items-center"
              label={t('applications.form.nomTechniqueLabel')}
              required
            />
            <FormInput
              fieldName="nomTechnique"
              register={register}
              error={errors?.nomTechnique}
              wrapperClassName="md:w-[300px]"
              inputClassName="w-full form-input mb-[10px]"
              wrapperErrorClassName="h-[30px]"
              readonly={!creation}
            />
          </div>
        </div>
        <ApplicationAccrocheInput
          register={register}
          error={errors?.accroche}
        />
        <div
          className={`text-left md:mt-[40px] my-[20px] w-full md:block ${isValid ? '' : 'hidden'}`}
        >
          <button
            className="small-btn cancel hidden w-auto mr-30px normal-case"
            onClick={cancelAction}
          >
            {t('global.form.button.cancel')}
          </button>
          <button
            type="submit"
            className="small-btn default w-full md:w-auto normal-case"
            disabled={isLoading}
          >
            {creation
              ? t('applications.form.createApp')
              : t('applications.form.edit.buttonSubmit')}
            {isLoading && (
              <span className="button-loader ml-2 align-middle"></span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
