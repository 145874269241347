import React from 'react'
import { ReactComponent as TagCloseButton } from 'core/assets/images/icones/closeIcon.svg'

export const TagWithDeleteButton: React.FC<{
  tag: { id: number; label: string }
  onClick: (id: number) => void
}> = ({ tag, onClick }) => {
  return (
    <div className="tag-button pr-2 group hover:bg-blue-1050 dark:hover:bg-gray-1450">
      <span>{tag.label}</span>
      <button
        type="button"
        onClick={() => onClick(tag.id)}
        className="delete-tag-button ml-3 focus:outline-none"
      >
        <TagCloseButton className="h-[16px]" />
      </button>
    </div>
  )
}
