import { defaultEnv } from 'core/constants/currentEnv'
import envConstants from 'core/constants/envConstants'

export const API_BASE_URL = envConstants[defaultEnv].API_BASE_URL
export const IADOCS_URL = envConstants[defaultEnv].IADOCS_URL
export const KEYCLOAK_URL = envConstants[defaultEnv].KEYCLOAK_URL
export const KEYCLOAK_REALM = envConstants[defaultEnv].KEYCLOAK_REALM
export const KEYCLOAK_CLIENT_ID = envConstants[defaultEnv].KEYCLOAK_CLIENT_ID
export const DEFAULT_APP_COLOR = '#48AC79'
export const DEFAULT_APP_DARK_COLOR = '#3D7AB4'
export const DEFAULT_APP_ICONE = 'iconeServiceDefaultSmall.svg'
export const DEFAULT_APP_ICONE_DARK = 'iconeServiceDefaultSmallDark.svg'
export const FILE_EXTENSION_GROUPS = {
  pdf: ['pdf'],
  doc: ['docx', 'doc', 'txt', 'txtplain'],
  image: ['jpg', 'jpeg', 'png'],
  excel: ['xlsx', 'xls', 'csv'],
  ppt: ['ppt'],
}
export const MAX_FILE_SIZE = 20971520
export const PROMPT_TEST_APP = 'promptix'
