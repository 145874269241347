import Card from 'core/components/common/Card'
import { useTranslation } from 'react-i18next'
import { IApplication } from 'core/interfaces/Application'
import { ApplicationIcon } from 'lauria/components/applications/ApplicationIcon'
import { FC } from 'react'
import { ReactComponent as Arrow } from 'core/assets/images/icones/arrow.svg'

export const ApplicationCard: FC<{
  application: IApplication
  handleClick?: () => void
  withLink?: boolean
  withBg?: boolean
}> = ({ application, handleClick, withLink = false, withBg = true }) => {
  const { t } = useTranslation()

  return (
    <Card
      className={`application-card mx-auto md:mx-0 cursor-pointer ${withBg ? 'dark:!bg-black' : '!bg-transparent hover:dark:!bg-gray-1425 !border-none hover:!bg-white-150'}`}
      onClick={handleClick}
      withHoverStyle={withBg}
    >
      <div className="flex flex-row w-full">
        <ApplicationIcon
          service={application.service}
          smallSize
          withBg={withBg}
        />
        <div className="flex flex-col flex-grow ml-10px mt-[2px] overflow-hidden">
          <div className="app-name">{application.nomApplication}</div>
          <div className="description">{application.description}</div>
        </div>
      </div>
      <div className="flex flex-grow">
        {withLink ? (
          <div className="flex flex-row w-auto items-center text-blue-1350 mx-auto">
            {t('iastart.button')}
            <Arrow className="ml-[20px]" />
          </div>
        ) : (
          <div className="leading-5 text-gray-1250 text-[11px] ml-[80px]">
            {t('applications.card.prompt', {
              count: application.totalPrompts,
            })}
            &nbsp;
            {t('applications.card.actif', { count: application.activePrompts })}
          </div>
        )}
      </div>
    </Card>
  )
}
