import { IAMessage } from 'core/components/conversation/IAMessage'
import { IMessage, UserType } from 'core/interfaces/Conversation'
import { UserMessage } from 'core/components/conversation/UserMessage'
import { MessageInput } from 'core/components/conversation/MessageInput'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ReloadIcon } from 'core/assets/images/icones/refreshIcon.svg'
import { IApplicationPromptVariable } from 'core/interfaces/Application'
import { ConversationHeader } from 'iastart/components/ConversationHeader'

export const ConversationComponent: React.FC<{
  handleResetConversation?: (app?: string) => void
  handleConverse: (message: string) => void | boolean
  isLoading: boolean
  messages: IMessage[]
  application: IApplicationPromptVariable
  withMessageLabel?: boolean
  withResetButton?: boolean
  smallAvatar?: boolean
}> = ({
  handleResetConversation = () => {},
  handleConverse,
  isLoading,
  messages,
  application,
  withMessageLabel = true,
  withResetButton = true,
  smallAvatar = false,
}) => {
  const [message, setMessage] = useState('')
  const { t } = useTranslation()
  const messagesContainerRef = useRef<HTMLDivElement | null>(null)

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages, isLoading])
  return (
    <>
      <div className="conversation">
        <div
          ref={messagesContainerRef}
          className="w-full flex-grow overflow-auto"
        >
          <ConversationHeader application={application} />
          <div className="messages-wrapper">
            <IAMessage
              message={{
                content:
                  application.accroche !== ''
                    ? application.accroche
                    : t('iastart.application.accroche', {
                        application: application.nomApplication,
                      }),
                type: UserType.ASSISTANT,
                timestamp: Date.now(),
              }}
              application={application.nomTechnique}
              first
              smallAvatar={smallAvatar}
            />
            {messages.map((message, index) => {
              return message.type === UserType.ASSISTANT ? (
                <IAMessage
                  key={index}
                  message={message}
                  application={application.nomTechnique}
                  smallAvatar={smallAvatar}
                  first={index === 0}
                />
              ) : (
                <UserMessage
                  key={index}
                  message={message.content}
                  smallAvatar={smallAvatar}
                />
              )
            })}
            {isLoading && (
              <>
                {message !== '' && (
                  <UserMessage message={message} smallAvatar={smallAvatar} />
                )}
                <IAMessage
                  message={{
                    content: '',
                    type: UserType.ASSISTANT,
                    timestamp: Date.now(),
                  }}
                  application={application.nomTechnique}
                  isLoading
                  smallAvatar={smallAvatar}
                />
              </>
            )}
          </div>
        </div>
        <div className="message-input-wrapper">
          {withResetButton && (
            <div
              className="reload-conversation"
              onClick={() => handleResetConversation(application.nomTechnique)}
            >
              <ReloadIcon className="mr-[10px]" />
              {t('iastart.newConversation')}
            </div>
          )}
          <MessageInput
            placeholderText={t('iastart.conversationPlaceholder')}
            onSend={(mess) => handleConverse(mess)}
            setMessage={setMessage}
            withLabel={withMessageLabel}
          />
        </div>
      </div>
    </>
  )
}
