import { FieldLabel } from 'core/components/common/FieldLabel'
import { useTranslation } from 'react-i18next'
import { IService } from 'core/interfaces/Service'
import { ApplicationIcon } from 'lauria/components/applications/ApplicationIcon'
import { FormInput } from 'core/components/common/FormInput'
import React from 'react'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { ApplicationForm } from 'lauria/hooks/useEditApplication'
import { FormTextarea } from 'core/components/common/FormTextarea'

export const ApplicationDescriptionCard: React.FC<{
  serviceSelected: IService | null
  register: UseFormRegister<ApplicationForm>
  errors: FieldErrors<ApplicationForm>
  handleIntituleBlur: () => void
}> = ({ serviceSelected, register, errors, handleIntituleBlur }) => {
  const { t } = useTranslation()
  return (
    <div>
      <div className="flex flex-row h-[45px] items-center">
        <FieldLabel label={t('applications.form.descriptionLabel')} required />
      </div>
      <div className="card app-description-card">
        <ApplicationIcon service={serviceSelected} />
        <div className="flex flex-col ml-[10px] w-full">
          <FormInput
            fieldName="intitule"
            register={register}
            error={errors.intitule}
            wrapperClassName="md:w-[320px]"
            inputClassName="w-full form-input mb-[10px]"
            wrapperErrorClassName="h-[30px]"
            placeholder={t('applications.form.intitulePlaceholder')}
            onBlur={handleIntituleBlur}
          />
          <FormTextarea
            fieldName="description"
            register={register}
            error={errors.description}
            wrapperClassName="md:w-[320px]"
            inputClassName="w-full form-input h-[60px] resize-none"
            maxLength={100}
            showCounter
            placeholder={t('applications.form.descriptionPlaceholder')}
          />
        </div>
      </div>
    </div>
  )
}
