import { KpiSideBar } from 'lauria/components/kpi/KpiSideBar'
import { KpiContent } from 'lauria/components/kpi/KpiContent'
import React, { useContext, useEffect } from 'react'
import Loader from 'core/components/common/Loader'
import { KpiPromptContext } from 'lauria/contexts/KpiPromptContext'
import { useGetGlobalKpisMutation, useGetKpisMutation } from 'lauria/api/kpi'
import { useParams } from 'react-router-dom'
import { useGetPromptsWithDetailsFromApplicationQuery } from 'lauria/api/prompt'
import { skipToken } from '@reduxjs/toolkit/query'
import { usePromptKpi } from 'lauria/hooks/usePromptKpi'
import { IPromptDetail } from 'core/interfaces/Prompt'

export const KpiPrompt = () => {
  const { idApplication } = useParams()
  const {
    start,
    end,
    period,
    selectedPrompts,
    setSelectedPrompts,
    setPromptsList,
    setKpis,
    setGlobalKpis,
    setOldGlobalKpis,
  } = useContext(KpiPromptContext)
  const { getBodyRequest } = usePromptKpi()

  const [getKpis, { isLoading: isLoadingKpi }] = useGetKpisMutation()
  const [getGlobalKpis, { isLoading: isLoadingKpiGlobal }] =
    useGetGlobalKpisMutation()
  const { data: prompts, isLoading: isLoadingPrompts } =
    useGetPromptsWithDetailsFromApplicationQuery(
      idApplication ? { applicationId: parseInt(idApplication) } : skipToken
    )

  useEffect(() => {
    if (prompts) {
      setPromptsList(prompts)
      const selected = prompts
        .filter(
          (prompt: any) => prompt.actif === true && prompt.suppression === false
        )
        .map((prompt: any) => prompt.id)
        .slice(0, 3)

      setSelectedPrompts(selected)
    }
  }, [prompts])

  useEffect(() => {
    if (idApplication && selectedPrompts.length > 0) {
      getKpis(
        getBodyRequest(idApplication, selectedPrompts, period, start, end)
      )
        .unwrap()
        .then((res) => {
          setKpis(res)
        })
        .catch((error) => {
          console.error(
            'Erreur lors de la récupération des données KPI:',
            error
          )
        })
    }
  }, [idApplication, selectedPrompts, start])
  useEffect(() => {
    const fetchGlobalData = async () => {
      if (idApplication && prompts) {
        const promptIds = prompts.map((prompt: IPromptDetail) => prompt.id)
        try {
          const requests = [
            getGlobalKpis(
              getBodyRequest(idApplication, promptIds, period, start, end)
            ).unwrap(),
            getGlobalKpis(
              getBodyRequest(idApplication, promptIds, period, start, end, true)
            ).unwrap(),
          ]

          const [currentGLobalKpis, previousGlobalKpis] =
            await Promise.all(requests)

          setGlobalKpis(currentGLobalKpis)
          setOldGlobalKpis(previousGlobalKpis)
        } catch (error) {
          console.error(
            'Erreur lors de la récupération des données KPI:',
            error
          )
        }
      }
    }
    fetchGlobalData()
  }, [prompts, start])

  if (isLoadingPrompts) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }
  if (!prompts) {
    return null
  }
  return (
    prompts && (
      <div className="h-full w-full flex flex-col">
        <div className="kpi-wrapper">
          <KpiSideBar />
          <KpiContent isLoading={isLoadingKpi || isLoadingKpiGlobal} />
        </div>
      </div>
    )
  )
}
