import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useMatch } from 'react-router'
import { ReactComponent as BackArrowIcon } from 'core/assets/images/icones/backArrow.svg'
import { NavLink, useParams } from 'react-router-dom'
import { ROUTE_PATHS } from 'core/constants/routePath'

export const ApplicationNavigation = () => {
  const { t } = useTranslation()
  const isCreatePage = useMatch(ROUTE_PATHS.APPLICATION_CREATION)
  const { idApplication } = useParams()

  return (
    <div className="application-navigation">
      <div className="flex flex-row h-full items-center w-full flex-1">
        <div className="flex flex-col w-full">
          <h3>{t('applications.title')}</h3>
          {!isCreatePage ? (
            <NavLink
              to={generatePath(ROUTE_PATHS.PROMPTS, {
                idApplication: idApplication,
              })}
              className="navLink mt-0"
            >
              <BackArrowIcon className="icon" />
              <span>{t('applications.goToPrompts')}</span>
            </NavLink>
          ) : (
            <NavLink
              to={generatePath(ROUTE_PATHS.DASHBOARD)}
              className="navLink mt-0"
            >
              <BackArrowIcon className="icon" />
              <span>{t('goToDashboard')}</span>
            </NavLink>
          )}
        </div>
      </div>
    </div>
  )
}
