import React from 'react'
import { AddButton } from 'core/components/common/AddButton'
import { Trans, useTranslation } from 'react-i18next'
import { IPromptDetail } from 'core/interfaces/Prompt'
import { PromptCard } from 'lauria/components/prompts/PromptCard'

export const PromptListCards: React.FC<{
  createPrompt: () => void
  prompts: IPromptDetail[] | undefined
  selectedPrompt: number | null
  setSelectedPrompt: (id: number) => void
}> = ({ createPrompt, prompts, selectedPrompt, setSelectedPrompt }) => {
  const { t } = useTranslation()
  const activePrompts = prompts?.filter(
    (prompt) => prompt.actif && !prompt.suppression
  )
  const inactivePrompts = prompts?.filter(
    (prompt) => !prompt.actif && !prompt.suppression
  )

  return (
    <div
      className={`prompt-list-cards-wrapper ${prompts && prompts.length > 0 ? 'w-[375px]' : 'min-w-[220px]'}`}
    >
      {prompts && prompts.length > 0 && (
        <div className="text-info-prompt">
          <div className="max-w-[290px]">
            <Trans
              i18nKey={'prompts.textPromptInfoDistribution'}
              components={{ strong: <strong className="font-medium" /> }}
            />
          </div>
        </div>
      )}
      <div className="p-[20px] w-full">
        <AddButton
          onClick={createPrompt}
          label={t('prompts.newPrompt')}
          classname="mx-auto"
        />
        {prompts && prompts.length > 1 && (
          <div className="mt-[20px] w-full">
            <div className="status-title">{t('prompts.textPromptActif')}</div>
            {activePrompts && activePrompts?.length > 0 ? (
              activePrompts.map((prompt, index) => (
                <PromptCard
                  key={index}
                  prompt={prompt}
                  selected={selectedPrompt === prompt.id}
                  setSelectedPrompt={setSelectedPrompt}
                />
              ))
            ) : (
              <div className="text-gray-1250 mb-10px">{t('noOne')}</div>
            )}
            <div className="status-title">{t('prompts.textPromptInactif')}</div>
            {inactivePrompts && inactivePrompts?.length > 0 ? (
              inactivePrompts.map((prompt, index) => (
                <PromptCard
                  key={index}
                  prompt={prompt}
                  selected={selectedPrompt === prompt.id}
                  setSelectedPrompt={setSelectedPrompt}
                />
              ))
            ) : (
              <div className="text-gray-1250">{t('noOne')}</div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
