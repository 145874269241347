import { useTranslation } from 'react-i18next'
import { KpiPromptCardList } from 'lauria/components/kpi/KpiPromptCardList'
import React, { useContext } from 'react'
import { KpiPromptContext } from 'lauria/contexts/KpiPromptContext'
import { KpiChart } from 'lauria/components/kpi/KpiChart'
import { PeriodSelector } from 'lauria/components/kpi/PeriodSelector'

export const KpiContent: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const { t } = useTranslation()
  const {
    currentDate,
    setCurrentDate,
    start,
    setStart,
    end,
    setEnd,
    activeMetric,
    valueType,
    period,
    setPeriod,
    setValueType,
  } = useContext(KpiPromptContext)
  const periodState = { period, setPeriod }
  const dateRange = {
    currentDate,
    setCurrentDate,
    start,
    setStart,
    end,
    setEnd,
  }

  return (
    <div className="kpi-content">
      <div className="kpi-periode">
        <PeriodSelector periodState={periodState} dateRange={dateRange} />
      </div>
      <div className="mt-[20px] pl-[20px]">
        <div className="flex flex-row items-center mb-[15px]">
          <div className="kpi-content-title">{t(activeMetric.title)}</div>
          <select
            className="w-[120px] form-input h-[30px] ml-[15px] cursor-pointer"
            value={valueType}
            onChange={(e) =>
              setValueType(e.target.value as 'TOTAL' | 'AVERAGE')
            }
          >
            <option value={'TOTAL'}>{t('prompts.kpi.value.total')}</option>
            <option value={'AVERAGE'}>{t('prompts.kpi.value.average')}</option>
          </select>
        </div>
        <div className="kpi-content-trait"></div>
        <>
          <KpiChart isLoading={isLoading} />
          <KpiPromptCardList />
        </>
      </div>
    </div>
  )
}
