import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetPromptsWithDetailsFromApplicationQuery } from 'lauria/api/prompt'
import { skipToken } from '@reduxjs/toolkit/query'
import { generatePath, useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'core/constants/routePath'

export const usePromptDistribution = () => {
  const { idApplication } = useParams()
  const navigate = useNavigate()
  const [selectedPrompt, setSelectedPrompt] = useState<number | null>(null)
  const {
    data: prompts,
    isLoading,
    isFetching,
    refetch: refetchPrompts,
  } = useGetPromptsWithDetailsFromApplicationQuery(
    idApplication ? { applicationId: parseInt(idApplication) } : skipToken
  )
  useEffect(() => {
    if (prompts && prompts.length > 0) {
      const filteredPrompts = prompts.filter((p) => !p.suppression)
      if (filteredPrompts.length > 0) {
        const firstActive = filteredPrompts.find((p) => p.actif)
        setSelectedPrompt(firstActive?.id ?? filteredPrompts[0].id)
      }
    } else {
      setSelectedPrompt(null)
    }
  }, [prompts, isFetching])

  const createPrompt = () => {
    if (idApplication) {
      navigate(
        generatePath(ROUTE_PATHS.PROMPT_CREATE, {
          idApplication: idApplication,
        })
      )
    }
  }

  return {
    isLoading,
    idApplication,
    createPrompt,
    prompts,
    selectedPrompt,
    setSelectedPrompt,
    refetchPrompts,
  }
}
