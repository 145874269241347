import { FormSelect } from 'core/components/common/FormSelect'
import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  ControllerRenderProps,
  UseFormGetValues,
  UseFormRegister,
} from 'react-hook-form'
import { PromptTestFormData } from 'lauria/hooks/usePromptTest'
import { useTranslation } from 'react-i18next'
import { useModelesLLM } from 'lauria/contexts/ModelesLLMContext'

export const PromptTestModele: React.FC<{
  register: UseFormRegister<PromptTestFormData>
  getFormTestValues: UseFormGetValues<PromptTestFormData>
  modele1Field: ControllerRenderProps<PromptTestFormData, 'modele1'>
}> = ({ register, getFormTestValues, modele1Field }) => {
  const { t } = useTranslation()
  const [modeleOptions, setModeleOptions] = useState<
    { value: string; label: string }[]
  >([])
  const [selectedModels, setSelectedModels] = useState<{
    [key: string]: string
  }>({
    modele1: getFormTestValues('modele1') || '',
    modele2: '',
  })
  const { modeles } = useModelesLLM()
  useEffect(() => {
    if (modeles) {
      setModeleOptions(
        modeles.map((modele) => ({
          value: modele.nom,
          label: modele.label,
        }))
      )
    }
  }, [modeles])

  const handleSelectChange = (
    fieldName: string,
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedValue = event.target.value
    setSelectedModels((prev) => ({
      ...prev,
      [fieldName]: selectedValue,
    }))
  }
  const filteredOptions = (selectedKey: string) =>
    modeleOptions.filter(
      (option) =>
        !Object.values(selectedModels).includes(option.value) ||
        selectedModels[selectedKey] === option.value
    )
  return (
    <div className="w-2/5 flex flex-col justify-end pb-[5px]">
      {modeleOptions && (
        <div className="prompt-test-model-wrapper">
          <div>{t('prompts.form.modelsToTest')}</div>
          <FormSelect
            wrapperClassName="w-full mt-[10px]"
            selectClassName="h-[30px] w-full form-input"
            fieldName="modele1"
            options={filteredOptions('modele1')}
            register={register}
            onChange={(e) => handleSelectChange('modele1', e)}
            value={modele1Field.value}
          />
          <FormSelect
            wrapperClassName="w-full mt-[10px]"
            selectClassName="h-[30px] w-full form-input"
            fieldName="modele2"
            options={filteredOptions('modele2')}
            register={register}
            onChange={(e) => handleSelectChange('modele2', e)}
          />
        </div>
      )}
    </div>
  )
}
