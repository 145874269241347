import { useTranslation } from 'react-i18next'
import React, { SetStateAction, useContext } from 'react'
import { ReactComponent as CheckedIcon } from 'core/assets/images/icones/checkedIcon.svg'
import { ReactComponent as CheckedIconDark } from 'core/assets/images/icones/checkedIconDark.svg'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'

export const KpiLineCard: React.FC<{
  showLine: boolean
  setShowLine: React.Dispatch<SetStateAction<boolean>>
  valueType: string
}> = ({ showLine, setShowLine, valueType }) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)
  return (
    <div className="flex flex-col w-[280px] mt-[15px]">
      <div
        className="kpi-prompt-card w-[280px] h-[40px] bg-green-1100 dark:bg-gray-1600"
        onClick={() => setShowLine(!showLine)}
      >
        <div className="left-border bg-blue-1500">
          <div className="kpi-prompt-card-content absolute left-[8px] bg-green-1100 dark:bg-gray-1600 w-[260px]">
            <span className="uppercase ml-[12px]">
              {valueType === 'AVERAGE'
                ? t('prompts.kpi.average')
                : t('prompts.kpi.total')}
            </span>
            {showLine && (
              <div className="flex items-center justify-center">
                {isDarkTheme ? (
                  <CheckedIconDark className="text-blue-1500" />
                ) : (
                  <CheckedIcon className="text-blue-1500" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
