import React from 'react'
import { usePromptVariables } from 'iastart/hooks/usePromptVariables'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SendIcon } from 'core/assets/images/icones/sendIcon.svg'
import { IApplicationPromptVariable } from 'core/interfaces/Application'
import { IDocument } from 'core/interfaces/Document'

export const VariablesInput: React.FC<{
  application: IApplicationPromptVariable
  setIsSending: React.Dispatch<React.SetStateAction<boolean>>
  documents: IDocument[]
}> = ({ application, setIsSending, documents }) => {
  const { variables, onSubmit, fields, register, handleSubmit } =
    usePromptVariables(application, setIsSending, documents)
  const { t } = useTranslation()

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-row bg-gray-1075 rounded-[15px] p-[15px]"
    >
      <div className="var-input-wrapper">
        {variables.length === 0 ? (
          <div className="flex items-center">{t('iastart.noVar')}</div>
        ) : (
          fields.map((field, index) => (
            <div key={field.name} className="flex items-center">
              <label
                htmlFor={`fields.${index}.value`}
                className="mr-2 w-[80px]"
              >
                {field.name}
              </label>
              <input
                id={`fields.${index}.value`}
                {...register(`fields.${index}.value`)}
                className="var-input"
                placeholder={t('iastart.placeholder')}
              />
            </div>
          ))
        )}
      </div>
      <button type="submit" className="btn-send-message btn-send-wrapper">
        <div className="uppercase mb-[5px] font-medium">
          {t('iastart.envoyer')}
        </div>
        <SendIcon />
      </button>
    </form>
  )
}
