import { IApplication } from 'core/interfaces/Application'
import React from 'react'
import { ApplicationCardList } from 'lauria/components/dashboard/ApplicationCardList'
import { ApplicationCardListMobile } from 'lauria/components/dashboard/ApplicationCardListMobile'
import { useTranslation } from 'react-i18next'
import Loader from 'core/components/common/Loader'
import { generatePath, useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { AddButton } from 'core/components/common/AddButton'

export const ApplicationCardListWrapper: React.FC<{
  applications: IApplication[]
  isLoading: boolean
  filtersApplied: boolean
  accessDashboard: boolean
}> = ({ applications, isLoading, filtersApplied, accessDashboard }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const goToNewApp = () => {
    navigate(generatePath(ROUTE_PATHS.APPLICATION_CREATION))
  }
  if (isLoading) {
    return <Loader pageCenter />
  }

  return (
    <div className="app-list-wrapper">
      <div className="header">
        <div className="flex flex-row items-center">
          <div>{t('services.myApp')}</div>
          {accessDashboard && (
            <AddButton
              onClick={goToNewApp}
              label={t('services.addNewApp')}
              classname="ml-auto"
            />
          )}
        </div>
        <div className="trait"></div>
      </div>
      <div className="hidden md:block">
        {accessDashboard ? (
          applications.length === 0 ? (
            filtersApplied ? (
              <span className="text-gray-1325">
                {t('applications.filter.noResult')}
              </span>
            ) : (
              <div>{t('applications.noApplications')}</div>
            )
          ) : (
            <ApplicationCardList applications={applications} />
          )
        ) : (
          <div className="text-red-1300">{t('applications.noTags')}</div>
        )}
      </div>
      <div
        className={`md:hidden block ${applications.length === 0 ? 'ml-[-10px] md:ml-0' : ''}`}
      >
        {applications.length === 0 ? (
          accessDashboard ? (
            filtersApplied ? (
              t('applications.filter.noResult')
            ) : (
              <span className="text-gray-1325">
                {t('applications.noApplications')}
              </span>
            )
          ) : (
            <span className="text-gray-1325">{t('applications.noAccess')}</span>
          )
        ) : (
          <ApplicationCardListMobile applications={applications} />
        )}
      </div>
    </div>
  )
}
