import { ReactComponent as PromptIcone } from 'lauria/assets/images/mobile/promptButton.svg'
import { ReactComponent as PromptIconeDark } from 'lauria/assets/images/mobile/promptButtonDark.svg'
import { ReactComponent as PlusButton } from 'lauria/assets/images/mobile/plusButton.svg'
import { ReactComponent as PlusButtonDark } from 'lauria/assets/images/mobile/plusButtonDark.svg'
import { ReactComponent as GoToIasuiteMobileDark } from 'lauria/assets/images/prompts/goToIasuiteMobileDark.svg'
import { ReactComponent as GoToIasuiteMobile } from 'lauria/assets/images/prompts/goToIasuiteMobile.svg'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import React, { useContext, useState } from 'react'
import { IApplication } from 'core/interfaces/Application'

export const PromptMobileMenu: React.FC<{
  application: IApplication | null
  createPrompt: () => void
  goToIasuite: () => void
}> = ({ application, createPrompt, goToIasuite }) => {
  const { isDarkTheme } = useContext(DarkThemeContext)
  const [hide, setHide] = useState<boolean>(true)
  const displayMenu = () => {
    setHide(!hide)
  }

  return (
    <div
      className="md:hidden absolute bottom-3.5 gap-x-[10px] right-3.5 flex flex-row items-center"
      onClick={displayMenu}
    >
      {isDarkTheme ? (
        <>
          {application && application.publiee && (
            <GoToIasuiteMobileDark
              className={`${hide ? 'hidden' : ''}`}
              onClick={goToIasuite}
            />
          )}
          <PlusButtonDark
            className={`${hide ? 'hidden' : ''}`}
            onClick={createPrompt}
          />
          <PromptIconeDark className="ml-[-10px]" />
        </>
      ) : (
        <>
          {application && application.publiee && (
            <GoToIasuiteMobile
              className={`${hide ? 'hidden' : ''}`}
              onClick={goToIasuite}
            />
          )}
          <PlusButton
            className={`${hide ? 'hidden' : ''}`}
            onClick={createPrompt}
          />
          <PromptIcone className="ml-[-10px]" />
        </>
      )}
    </div>
  )
}
