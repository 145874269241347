import React, { createContext, useContext } from 'react'
import { IModele } from 'core/interfaces/Modele'
import { useGetAllModelesQuery } from 'lauria/api/referentielModeles'

type ModelesLLMContextType = {
  modeles: IModele[]
  isLoading: boolean
  getModeleLabelById: (id: number | string | null | undefined) => string
}
const ModelesLLMContext = createContext<ModelesLLMContextType>({
  modeles: [],
  isLoading: false,
  getModeleLabelById: () => '',
})

export const ModelesLLMProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data, isLoading } = useGetAllModelesQuery()

  const getModeleLabelById = (
    id: number | string | null | undefined
  ): string => {
    if (!id || !data) return ''
    const numId = typeof id === 'string' ? parseInt(id, 10) : id
    const modele = data.find((m) => m.id === numId)
    return modele?.label ?? ''
  }

  return (
    <ModelesLLMContext.Provider
      value={{ modeles: data ?? [], isLoading, getModeleLabelById }}
    >
      {children}
    </ModelesLLMContext.Provider>
  )
}

export const useModelesLLM = () => {
  const context = useContext(ModelesLLMContext)
  if (!context) {
    throw new Error('useModeles must be used within a ModelesLLMProvider')
  }
  return context
}
