import { useTranslation } from 'react-i18next'
import React from 'react'
import { ReactComponent as ReloadIcon } from 'core/assets/images/icones/refreshIcon.svg'
import { usePromptTest } from 'lauria/hooks/usePromptTest'
import { UseFormGetValues } from 'react-hook-form'
import { PromptForm } from 'core/interfaces/Prompt'
import { PromptTestModele } from 'lauria/components/prompts/PromptTestModele'
import { Documents } from 'core/components/documents/Documents'
import { ReactComponent as TestIcon } from 'core/assets/images/icones/test.svg'
import { useModelesLLM } from 'lauria/contexts/ModelesLLMContext'

export const PromptTest: React.FC<{
  getValues: UseFormGetValues<PromptForm>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ getValues, setIsLoading }) => {
  const { t } = useTranslation()
  const { modeles } = useModelesLLM()
  const {
    fields,
    handleSubmit,
    register,
    onSubmit,
    getFormTestValues,
    modele1Field,
    refreshVars,
    documentsTest,
    setDocumentsTest,
  } = usePromptTest(getValues, setIsLoading)

  return (
    <div className="prompt-test-wrapper">
      <form id="test-form" onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="my-[15px]">{t('prompts.form.testPrompt')}</div>
        <div className="flex flex-row w-full items-start">
          {modeles && (
            <PromptTestModele
              register={register}
              getFormTestValues={getFormTestValues}
              modele1Field={modele1Field}
            />
          )}
          <div className="prompt-test-fields-wrapper">
            <div className="flex flex-row w-full">
              <table className="w-3/5">
                <tbody>
                  <tr className="h-[40px]">
                    <td>{t('prompts.form.variable')}</td>
                    <td className="pl-[20px]">{t('prompts.form.content')}</td>
                  </tr>
                  {fields.length > 0 ? (
                    fields.map((field, index) => (
                      <tr key={index} className="h-[50px]">
                        <td>
                          <div className="test-var">{field.name}</div>
                        </td>
                        <td>
                          <input
                            {...register(`fields.${index}.value`)}
                            className="form-input ml-[20px] h-[40px] w-[200px]"
                            type="text"
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={2} className="text-gray-1250">
                        {t('prompts.form.noVariable')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Documents
              documents={documentsTest}
              setDocuments={setDocumentsTest}
              wfull
            />
          </div>
        </div>
        <div className="flex flex-row items-center justify-between w-full mt-[30px]">
          <div
            className="mx-auto reload-variables btn-without-bg text-yellow-1700 border-yellow-1700 h-[30px] bg-white dark:bg-gray-1600 mb-10px"
            onClick={(event) => {
              event.preventDefault()
              refreshVars()
            }}
          >
            <ReloadIcon className="mr-[10px]" />
            {t('prompts.form.refresh')}
          </div>
          <button
            type="submit"
            className="ml-0 btn-without-bg btn-launch-test h-[30px] bg-white dark:bg-gray-1600"
          >
            <TestIcon className="mr-10px" />
            {t('prompts.form.launchTest')}
          </button>
        </div>
      </form>
    </div>
  )
}
