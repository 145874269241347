import Loader from 'core/components/common/Loader'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PromptModeleTag } from 'lauria/components/prompts/PromptModeleTag'
import { TemperatureSlider } from 'lauria/components/common/TemperatureSlider'
import { NbDocIcon } from 'lauria/components/common/NbDocIcon'
import { DocumentCard } from 'core/components/documents/DocumentCard'
import { PromptContentCard } from 'lauria/components/prompts/PromptContentCard'
import { PromptSwitchStatus } from 'lauria/components/prompts/PromptSwitchStatus'
import { RemovePromptModal } from 'lauria/components/prompts/RemovePromptModal'
import { ChangePromptStatusModal } from 'lauria/components/prompts/ChangePromptStatusModal'
import { usePromptContent } from 'lauria/hooks/usePromptContent'

export const Prompt: React.FC<{
  promptId: number
  refetchPrompts: any
}> = ({ promptId, refetchPrompts }) => {
  const {
    changeStatus,
    handleAppClick,
    openRemoveModal,
    setOpenRemoveModal,
    openChangeStatusModal,
    setOpenChangeStatusModal,
    isUpdating,
    isLoading,
    prompt,
    handleDelete,
    isDeleting,
  } = usePromptContent(promptId, refetchPrompts)
  const { t } = useTranslation()
  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }

  return (
    <div className="prompt-content-wrapper">
      {prompt && (
        <div className="flex flex-col w-full h-full">
          <div className="content-prompt w-full">
            <div className="content-prompt-fields">
              <div className="prompt-content-title">{prompt.nomPrompt}</div>
              <div className="flex flex-row items-center">
                <div className="label">
                  {t('prompts.form.modeleDispoLabel')}
                </div>
                <div>
                  {prompt.modele && <PromptModeleTag modele={prompt.modele} />}
                </div>
              </div>
              <div className="flex flex-row items-center mt-10px">
                <div className="label">
                  {t('prompts.form.temperatureLabel')}
                </div>
                <div>
                  <TemperatureSlider value={prompt.temperature ?? 0.5} />
                </div>
              </div>
              {prompt.documents && prompt.documents?.length > 0 && (
                <div className="prompt-documents">
                  <div className="flex flex-row items-center">
                    <NbDocIcon nbDoc={prompt.documents?.length} />
                    <span className="font-semibold ml-[10px]">
                      {t('prompts.documents.documentsAttached')}
                    </span>
                  </div>
                  <div className="mt-[10px]">
                    {prompt.documents.map((doc, index) => (
                      <DocumentCard filename={doc.nomDocument} key={index} />
                    ))}
                  </div>
                </div>
              )}
              <div className="flex flex-row pt-30px w-full">
                <div className="w-full">
                  <PromptContentCard prompt={prompt} />
                </div>
              </div>
              <div className="flex md:flex-row flex-col-reverse pt-30px w-full md:justify-between">
                <div className="flex md:flex-row flex-col-reverse items-center gap-x-[30px]">
                  <button
                    className="small-btn cancel md:mt-[30px] mt-10px w-full md:w-[180px] md:rounded-[5px] rounded-[0]"
                    onClick={() => setOpenRemoveModal(true)}
                  >
                    {t('prompts.delete')}
                  </button>
                  <button
                    className="small-btn default md:mt-[30px] mt-[20px] w-full md:w-[180px] md:rounded-[5px] rounded-[0]"
                    onClick={handleAppClick}
                  >
                    {t('prompts.edit')}
                  </button>
                </div>
                <PromptSwitchStatus
                  isActive={prompt.actif ?? false}
                  onClick={() => setOpenChangeStatusModal(true)}
                />
              </div>
            </div>
          </div>
          <RemovePromptModal
            open={openRemoveModal}
            setOpen={setOpenRemoveModal}
            handleClick={handleDelete}
            isDeleting={isDeleting}
          />
          <ChangePromptStatusModal
            prompt={prompt}
            open={openChangeStatusModal}
            setOpen={setOpenChangeStatusModal}
            handleClick={changeStatus}
            isLoading={isUpdating}
          />
        </div>
      )}
    </div>
  )
}
