import { IApplication } from 'core/interfaces/Application'
import React from 'react'
import { ApplicationCard } from 'lauria/components/dashboard/ApplicationCard'
import { generatePath, useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'core/constants/routePath'

export const ApplicationCardList: React.FC<{
  applications: IApplication[]
}> = ({ applications }) => {
  const navigate = useNavigate()

  const redirectApp = (appId: number) => {
    navigate(generatePath(ROUTE_PATHS.PROMPTS, { idApplication: appId }))
  }

  return (
    <div className="card-list hidden md:flex">
      {applications.map((app) => {
        return (
          <div key={app.id} className="flex flex-col w-[290px]">
            <div className="text-[11px] text-gray-1250">
              {app.service.nomService}
            </div>
            <ApplicationCard
              application={app}
              handleClick={() => redirectApp(app.id)}
            />
            <div className="w-full h-auto flex flex-wrap mt-[5px]">
              {app.tags?.map((tag) => {
                return (
                  <div key={tag.id} className="tag-button mb-[5px]">
                    <span className="text-app">{tag.nomTag}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}
