import { useTranslation } from 'react-i18next'

export const PromptSwitchStatus: React.FC<{
  isActive: boolean
  onClick: () => void
}> = ({ isActive, onClick }) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-row items-center gap-x-[20px] ">
      <span
        className={`font-medium text-[16px]
          ${isActive ? 'text-green-1500' : 'text-red-1250'}`}
      >
        {isActive
          ? t('prompts.textPromptActif')
          : t('prompts.textPromptInactif')}
      </span>
      <button
        onClick={() => onClick()}
        className={`w-[70px] h-[30px] flex items-center rounded-full px-[5px] ${
          isActive ? 'bg-green-1500' : 'bg-red-1250'
        }`}
      >
        <div
          className={`bg-white w-[23px] h-[23px] rounded-full shadow-md transform transition-transform duration-300 ${
            isActive ? 'translate-x-[35px]' : 'translate-x-[2px]'
          }`}
        ></div>
      </button>
    </div>
  )
}
