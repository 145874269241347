import React from 'react'
import Loader from 'core/components/common/Loader'
import { useAppIastart } from 'iastart/hooks/useAppIastart'
import { ConversationContent } from 'iastart/components/ConversationContent'
import { PromptTest } from 'iastart/components/PromptTest'

export const IaStartApps = () => {
  const { isApplicationLoading, application } = useAppIastart()

  if (isApplicationLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }
  return (
    <div className="iastart-wrapper">
      {application && (
        <div className="flex flex-col items-center w-full">
          {application.service.id === 2 || application.service.id === 4 ? (
            <ConversationContent application={application} />
          ) : (
            <PromptTest application={application} />
          )}
        </div>
      )}
    </div>
  )
}
