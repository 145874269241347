import { useParams } from 'react-router-dom'
import { useGetApplicationPromptVariablesByClientQuery } from 'core/api/application'
import { skipToken } from '@reduxjs/toolkit/query'
import { useContext } from 'react'
import { UserContext } from 'core/contexts/UserContext'

export const useAppIastart = () => {
  const { app } = useParams()
  const { user: currentUser } = useContext(UserContext)

  const idClient = currentUser?.idClient
  const { data: application, isLoading: isApplicationLoading } =
    useGetApplicationPromptVariablesByClientQuery(
      idClient && app ? { idClient, applicationName: app } : skipToken
    )

  return {
    isApplicationLoading,
    application: application ?? null,
  }
}
