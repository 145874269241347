import { PromptTest } from 'lauria/components/prompts/PromptTest'
import React, { MutableRefObject, useState } from 'react'
import {
  ControllerRenderProps,
  FieldErrors,
  UseFormGetValues,
} from 'react-hook-form'
import { PromptForm } from 'core/interfaces/Prompt'
import { PromptField } from 'lauria/components/prompts/PromptField'
import { PromptTestConversation } from 'lauria/components/prompts/PromptTestConversation'
import { usePromptConversation } from 'lauria/contexts/PromptConversationContext'

export const PromptFormSide: React.FC<{
  getValues: UseFormGetValues<PromptForm>
  promptRef: React.RefObject<HTMLTextAreaElement>
  errors: FieldErrors<PromptForm>
  promptField: ControllerRenderProps<PromptForm, 'prompt'>
  addTextToTextarea: (
    event: React.MouseEvent,
    text: string,
    ref: MutableRefObject<any>,
    fieldName: string
  ) => void
}> = ({ getValues, promptRef, promptField, errors, addTextToTextarea }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { conversation } = usePromptConversation()
  return (
    <div className="prompt-form-test">
      <div
        className={`prompt-form-side ${conversation.messages.length > 0 || isLoading ? 'flex-[5]' : 'flex'}`}
      >
        <PromptField
          promptField={promptField}
          promptRef={promptRef}
          addTextToTextarea={addTextToTextarea}
          errors={errors}
        />
        <PromptTest getValues={getValues} setIsLoading={setIsLoading} />
      </div>
      {(conversation.messages.length > 0 || isLoading) && (
        <PromptTestConversation getValues={getValues} isLoading={isLoading} />
      )}
    </div>
  )
}
