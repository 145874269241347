import {
  DEFAULT_APP_COLOR,
  DEFAULT_APP_DARK_COLOR,
  DEFAULT_APP_ICONE,
  DEFAULT_APP_ICONE_DARK,
} from 'core/constants/general'
import { IApplication } from 'core/interfaces/Application'
import { IService, IServiceGroupedApplications } from 'core/interfaces/Service'
import { PERIOD } from 'core/constants/period'
import { add, startOfMonth, startOfWeek, sub } from 'date-fns'

export const getIconeInfo = (service: IService) => {
  let color, colorDark, svgPath, svgPathDark, imageSmall, imageSmallDark
  try {
    const parsedIcon = service && JSON.parse(service?.icone)
    if (parsedIcon) {
      ;({ color, colorDark, imageSmall, imageSmallDark } = parsedIcon)
    }
    svgPath = require(`core/assets/images/logos/${imageSmall}`)
    svgPathDark = require(`core/assets/images/logos/${imageSmallDark}`)
  } catch (error) {
    color = DEFAULT_APP_COLOR
    colorDark = DEFAULT_APP_DARK_COLOR
    svgPath = require(`core/assets/images/logos/${DEFAULT_APP_ICONE}`)
    svgPathDark = require(`core/assets/images/logos/${DEFAULT_APP_ICONE_DARK}`)
    imageSmall = DEFAULT_APP_ICONE
    imageSmallDark = DEFAULT_APP_ICONE_DARK
  }

  return { color, colorDark, svgPath, svgPathDark, imageSmall, imageSmallDark }
}

export const groupByService = (applications: IApplication[]) => {
  return applications.reduce((acc, app) => {
    const serviceName = app.service.nomService
    const serviceIcone = getIconeInfo(app.service)
    const newAcc = { ...acc }
    if (!newAcc[serviceName]) {
      newAcc[serviceName] = {
        service: serviceIcone,
        apps: [],
      }
    }
    newAcc[serviceName].apps.push(app)

    return newAcc
  }, {} as IServiceGroupedApplications)
}

export const getPeriodDates = (
  period: string,
  currentDate: Date
): [Date, Date] => {
  const periodMap = {
    [PERIOD.HEBDO]: [
      startOfWeek(currentDate, { weekStartsOn: 1 }),
      add(startOfWeek(currentDate, { weekStartsOn: 1 }), { days: 6 }),
    ],
    [PERIOD.MENSUELLE]: [
      startOfMonth(currentDate),
      add(startOfMonth(currentDate), { months: 1, days: -1 }),
    ],
    [PERIOD.SEMESTRIELLE]: [add(currentDate, { months: -5 }), currentDate],
    [PERIOD.ANNUELLE]: [sub(currentDate, { months: 12 }), currentDate],
  } as Record<string, [Date, Date]>
  return periodMap[period] ?? [currentDate, currentDate]
}

export const getLastApplications = (
  apps: IApplication[],
  nbApp: number = 3
): IApplication[] => {
  return [...apps]
    .sort(
      (a, b) =>
        new Date(b.dateModification).getTime() -
        new Date(a.dateModification).getTime()
    )
    .slice(0, nbApp)
}
