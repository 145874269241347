import { FieldError } from 'react-hook-form'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const FormTextarea: React.FC<{
  fieldName: string
  register: any
  wrapperClassName?: string
  inputClassName?: string
  wrapperErrorClassName?: string
  error?: FieldError
  readonly?: boolean
  placeholder?: string
  maxLength?: number
  showCounter?: boolean
  onChange?: (e?: any) => void
}> = ({
  fieldName,
  wrapperClassName,
  inputClassName,
  wrapperErrorClassName,
  register,
  error,
  readonly = false,
  placeholder,
  maxLength,
  showCounter = false,
  onChange,
}) => {
  const { t } = useTranslation()
  const [charCount, setCharCount] = useState(0)

  return (
    <div className={`relative ${wrapperClassName ?? ''}`}>
      <textarea
        name={fieldName}
        className={`${inputClassName ?? ''} ${error ? 'error' : ''}`}
        placeholder={
          placeholder ? placeholder : t('global.form.input.placeholder')
        }
        {...register(fieldName, {
          required: true,
          maxLength: maxLength || undefined,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            setCharCount(e.target.value.length)
            onChange?.()
          },
        })}
        readOnly={readonly}
        maxLength={maxLength}
      />
      <div
        className={`${wrapperErrorClassName ?? ''} ${error ? 'block' : 'hidden'}`}
      >
        {error?.type === 'required' && (
          <p className="form-error">{t('global.form.error.mandatory')}</p>
        )}
        {error?.type === 'maxLength' && (
          <p className="form-error">
            {t('global.form.error.maxLength', { max: maxLength })}
          </p>
        )}
        {error?.type === 'custom' && (
          <p className="form-error">{error.message}</p>
        )}
      </div>

      {showCounter && maxLength && (
        <div className="absolute bottom-[-14px] right-0 text-[11px]">
          {charCount}/{maxLength}
        </div>
      )}
    </div>
  )
}
