import { KpiCard } from 'lauria/components/kpi/KpiCard'
import { kpiMetrics } from 'core/constants/kpi'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const KpiSideBar = () => {
  const { t } = useTranslation()
  return (
    <div className="sidebar kpi-sidebar">
      <div className="kpi-title">{t('prompts.kpi.title')}</div>
      {kpiMetrics.map((metric, index) => {
        return <KpiCard key={index} metric={metric} />
      })}
    </div>
  )
}
