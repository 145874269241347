import { useGetAllApplicationsByClientFilterByTagsQuery } from 'core/api/application'
import { UserCard } from 'lauria/components/layout/UserCard'
import { UseKeycloakService } from 'core/packages/KeycloakProvider'
import { ApplicationFilters } from 'lauria/components/dashboard/ApplicationFilters'
import React, { useContext, useEffect, useState } from 'react'
import { IApplication } from 'core/interfaces/Application'
import { MobileMenu } from 'lauria/components/dashboard/MobileMenu'
import { ApplicationCardListWrapper } from 'lauria/components/dashboard/ApplicationCardListWrapper'
import { FilterModal } from 'lauria/components/dashboard/FilterModal'
import { UserCardMobile } from 'lauria/components/layout/UserCardMobile'
import { UserContext } from 'core/contexts/UserContext'
import { skipToken } from '@reduxjs/toolkit/query'
import Loader from 'core/components/common/Loader'

export const Dashboard = () => {
  const [applications, setApplications] = useState<IApplication[]>([])
  const [filtersApplied, setFiltersApplied] = useState(false)

  const [isAppLoading, setIsAppLoading] = useState(false)
  const [openFilter, setOpenFilter] = useState(false)

  const [selectedTags, setSelectedTags] = useState<
    { id: number; label: string }[]
  >([])
  const [service, setService] = useState('')

  const { user: currentUser, isSuperAdmin: isCurrentUserSuperAdmin } =
    useContext(UserContext)

  const idClient = currentUser?.idClient
  const { data, isLoading, isFetching } =
    useGetAllApplicationsByClientFilterByTagsQuery(
      idClient
        ? {
            idClient,
            services: service ? [parseInt(service)] : [],
            tags: selectedTags.map((tag) => tag.id),
          }
        : skipToken
    )

  const tagIds = [
    ...(currentUser?.tagsUtilisateur ?? []),
    ...(currentUser?.tagsAdministrateur ?? []),
  ]

  const accessDashboard =
    (isCurrentUserSuperAdmin || tagIds.length > 0) ?? false

  const openFilterModal = () => {
    setOpenFilter(true)
  }

  useEffect(() => {
    setIsAppLoading(isFetching)

    if (!isFetching && data) {
      setApplications(data)
    }
  }, [isFetching, data])

  const keycloak = UseKeycloakService()

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }

  if (!applications) {
    return null
  }

  return (
    applications && (
      <div className="dashboard-wrapper">
        <div className="sidebar dashboard-side">
          <UserCard username={keycloak.getName()} />
          {accessDashboard && (
            <ApplicationFilters
              setSelectedTags={setSelectedTags}
              selectedTags={selectedTags}
              service={service}
              setService={setService}
              setFiltersApplied={setFiltersApplied}
            />
          )}
        </div>
        <UserCardMobile username={keycloak.getName()} />
        <ApplicationCardListWrapper
          applications={applications}
          isLoading={isAppLoading}
          filtersApplied={filtersApplied}
          accessDashboard={accessDashboard}
        />
        <MobileMenu
          openFilterModal={openFilterModal}
          displayAddButton={applications.length > 0 && accessDashboard}
        />
        <FilterModal
          open={openFilter}
          setOpen={setOpenFilter}
          setApplications={setApplications}
        />
      </div>
    )
  )
}
