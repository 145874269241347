import { UserMessage } from 'core/components/conversation/UserMessage'
import { IAMessage } from 'core/components/conversation/IAMessage'
import { UserType } from 'core/interfaces/Conversation'
import { PromptTestResult } from 'lauria/components/prompts/PromptTestResult'
import React from 'react'
import { useBoostPrompt } from 'lauria/hooks/useBoostPrompt'
import { UseFormGetValues } from 'react-hook-form'
import { PromptForm } from 'core/interfaces/Prompt'
import { MessageInput } from 'core/components/conversation/MessageInput'
import { useTranslation } from 'react-i18next'
import { useModelesLLM } from 'lauria/contexts/ModelesLLMContext'

export const PromptTestConversation: React.FC<{
  getValues: UseFormGetValues<PromptForm>
  isLoading: boolean
}> = ({ getValues, isLoading }) => {
  const { t } = useTranslation()
  const { modeles } = useModelesLLM()
  const {
    handleConverse,
    isLoading: isLoadingConversation,
    messagesContainerRef,
    conversation,
    isIMessage,
    userMessage,
    setUserMessage,
  } = useBoostPrompt(getValues)

  return (
    <div className="sidebar prompt-test-side">
      <div
        ref={messagesContainerRef}
        className="w-full flex-grow overflow-auto"
      >
        <div className="messages-wrapper mb-10px">
          {conversation.messages.map((message, index) => {
            if (isIMessage(message)) {
              return message.type === UserType.ASSISTANT ? (
                <IAMessage
                  key={index}
                  message={message}
                  smallAvatar
                  first={index === 0}
                />
              ) : (
                <UserMessage
                  key={index}
                  message={message.content}
                  smallAvatar
                />
              )
            } else {
              return (
                <PromptTestResult
                  key={index}
                  answers={message.answers}
                  modeles={modeles}
                />
              )
            }
          })}
          {(isLoading || isLoadingConversation) && (
            <>
              {userMessage !== '' && <UserMessage message={userMessage} />}
              <IAMessage
                message={{
                  content: '',
                  type: UserType.ASSISTANT,
                  timestamp: Date.now(),
                }}
                isLoading
                smallAvatar
              />
            </>
          )}
        </div>
      </div>
      <div className="message-input-wrapper">
        <MessageInput
          placeholderText={t('prompts.form.promptTestPlaceholder')}
          onSend={(mess) => handleConverse(mess)}
          setMessage={setUserMessage}
          withLabel={false}
        />
      </div>
    </div>
  )
}
