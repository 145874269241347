import { FieldError } from 'react-hook-form'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const FormInput: React.FC<{
  fieldName: string
  register: any
  wrapperClassName?: string
  inputClassName?: string
  wrapperErrorClassName?: string
  error?: FieldError
  readonly?: boolean
  placeholder?: string
  onChange?: (e?: any) => void
  onBlur?: () => void
  id?: string
}> = ({
  fieldName,
  wrapperClassName,
  inputClassName,
  wrapperErrorClassName,
  register,
  error,
  readonly = false,
  placeholder,
  onChange,
  onBlur,
  id,
}) => {
  const { t } = useTranslation()

  return (
    <div className={`${wrapperClassName ?? ''}`}>
      <input
        name={fieldName}
        className={`${inputClassName ?? ''} ${error ? 'error' : ''} ${readonly ? 'disabled' : ''}`}
        placeholder={
          placeholder ? placeholder : t('global.form.input.placeholder')
        }
        {...register(fieldName, { required: true })}
        readOnly={readonly}
        onChange={(e) => {
          register(fieldName)?.onChange(e)
          onChange?.(e)
        }}
        onBlur={(e) => {
          register(fieldName)?.onBlur(e)
          onBlur?.()
        }}
        id={id}
      />
      <div
        className={`${wrapperErrorClassName ?? ''} ${error ? 'block' : 'hidden'}`}
      >
        {error?.type === 'required' && (
          <p className="form-error">{t('global.form.error.mandatory')}</p>
        )}
        {error?.type === 'custom' && (
          <p className="form-error">{error.message}</p>
        )}
      </div>
    </div>
  )
}
