import { IModeleAnswer } from 'core/interfaces/Ask'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { IModele } from 'core/interfaces/Modele'

export const PromptTestResult: React.FC<{
  answers: IModeleAnswer[]
  modeles: IModele[] | undefined
}> = ({ answers, modeles }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="w-full flex flex-row gap-x-[20px]">
        {answers.length > 0 ? (
          answers.map((answer, index) => (
            <div
              key={index}
              className="prompt-test-result bg-white dark:bg-gray-1600"
            >
              <div className="answer-parameter">
                <div className="font-medium">
                  {
                    modeles?.find((modele) => modele.nom === answer.modeleName)
                      ?.label
                  }
                </div>
                <div>
                  {answer.time && Math.round(answer.time * 100) / 100}
                  ms&nbsp;/&nbsp;
                  {Math.round(answer.answer.total_cost * 100000) / 100000}€
                </div>
              </div>
              <div>{answer.answer.answer}</div>
            </div>
          ))
        ) : (
          <div className="prompt-test-result text-gray-1250 bg-white-150 dark:bg-gray-1600">
            <div className="font-medium mb-[10px]">
              {t('prompts.form.result')}
            </div>
            <span>{t('prompts.form.noResult')}</span>
          </div>
        )}
      </div>
    </div>
  )
}
