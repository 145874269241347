import React, { useState } from 'react'
import { ReactComponent as PromptIcon } from 'lauria/assets/images/prompts/promptIcon.svg'
import { useTranslation } from 'react-i18next'
import { IApplication } from 'core/interfaces/Application'
import { ReactComponent as AppOptionIcon } from 'core/assets/images/icones/appOption.svg'
import { ReactComponent as AppPublicationIcon } from 'core/assets/images/icones/appPublication.svg'
import { ReactComponent as KPIIcon } from 'lauria/assets/images/prompts/kpiIcon.svg'
import { NavLink } from 'react-router-dom'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { generatePath, useNavigate } from 'react-router'
import { FormPublicationApplicationModal } from 'lauria/components/applications/FormPublicationApplicationModal'

export interface PromptSubHeaderProps {
  application: IApplication
}

export const PromptSubHeader: React.FC<PromptSubHeaderProps> = ({
  application,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [openPublication, setOpenPublication] = useState<boolean>(false)
  const goToApp = () => {
    navigate(
      generatePath(ROUTE_PATHS.APPLICATION_EDITION, {
        idApplication: application.id,
      })
    )
  }
  const openModalPublication = () => {
    setOpenPublication(true)
  }

  return (
    <div className="prompt-sub-header">
      <div className="tabs-prompt">
        <NavLink
          to={generatePath(ROUTE_PATHS.PROMPTS, {
            idApplication: application.id,
          })}
          end
          className={({ isActive }) =>
            `pl-[30px] tab-link-prompt ${isActive ? 'tab-prompt-active' : ''}`
          }
        >
          <div className="tab pl-[25px]">
            <PromptIcon className="h-[30px]" />
            <div className="flex flex-col ml-8">
              <h3>{t('prompts.title')}</h3>
              <span>{t('prompts.subtitle')}</span>
            </div>
          </div>
        </NavLink>
        <NavLink
          to={generatePath(ROUTE_PATHS.KPI, {
            idApplication: application.id,
          })}
          className={({ isActive }) =>
            `tab-link-prompt ${isActive ? 'tab-prompt-active' : ''}`
          }
        >
          <div className="tab border-l border-gray-1350 border-opacity-50 pl-[20px]">
            <KPIIcon className="h-[30px]" />
            <div className="flex flex-col ml-15px">
              <h3>{t('prompts.kpi.title')}</h3>
              <span>{t('prompts.kpi.subTitle')}</span>
            </div>
          </div>
        </NavLink>
      </div>
      <div className="ml-auto flex items-center mr-60px">
        <div
          className={`btn-publication ${application.publiee ? 'publiee' : 'non-publiee'}`}
          onClick={openModalPublication}
        >
          <AppPublicationIcon className="h-[20px]" />
          <span className="font-medium leading-[20px]">
            {application.publiee
              ? t('applications.publiee')
              : t('applications.nonPubliee')}
          </span>
        </div>
        <div className="btn-option" onClick={goToApp}>
          <AppOptionIcon className="h-[20px]" />
          <span className="font-medium leading-[20px]">
            {t('prompts.options')}
          </span>
        </div>
      </div>
      <FormPublicationApplicationModal
        open={openPublication}
        setOpen={setOpenPublication}
        application={application}
      />
    </div>
  )
}
