import React, { useContext } from 'react'
import Loader from 'core/components/common/Loader'
import { usePromptDistribution } from 'lauria/hooks/usePromptDistribution'
import { PromptMobileMenu } from 'lauria/components/prompts/PromptMobileMenu'
import { ReactComponent as GoToIasuite } from 'lauria/assets/images/prompts/goToIasuite.svg'
import { DarkThemeContext } from 'core/contexts/DarkThemeContext'
import { generatePath } from 'react-router'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { useSelector } from 'react-redux'
import { promptLayout } from 'lauria/store/slices/promptLayout'
import { PromptListCards } from 'lauria/components/prompts/PromptListCards'
import { Prompt } from 'lauria/components/prompts/Prompt'
import { useTranslation } from 'react-i18next'
import { ReactComponent as BackArrowIcon } from 'core/assets/images/icones/backArrow.svg'

export const PromptList = () => {
  const { isDarkTheme } = useContext(DarkThemeContext)
  const { application } = useSelector(promptLayout)
  const { t } = useTranslation()
  const {
    prompts,
    isLoading,
    createPrompt,
    selectedPrompt,
    setSelectedPrompt,
    refetchPrompts,
  } = usePromptDistribution()
  const goToIasuite = () => {
    if (application) {
      window.open(
        generatePath(ROUTE_PATHS.IASTART_APP, {
          app: application.nomTechnique,
        }),
        '_blank'
      )
    }
  }

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader pageCenter />
      </div>
    )
  }

  return (
    <>
      <div className="block md:hidden">
        {selectedPrompt === null ? (
          <PromptListCards
            createPrompt={createPrompt}
            prompts={prompts}
            selectedPrompt={selectedPrompt}
            setSelectedPrompt={setSelectedPrompt}
          />
        ) : (
          <div className="relative w-full">
            <button
              onClick={() => setSelectedPrompt(null)}
              className="absolute top-4 left-4 text-blue-1350 font-medium flex items-center flex-row gap-x-[10px]"
            >
              <BackArrowIcon className="icon" />
              <span>{t('applications.goToPrompts')}</span>
            </button>
            <Prompt promptId={selectedPrompt} refetchPrompts={refetchPrompts} />
          </div>
        )}
      </div>
      <div className="hidden md:flex flex-row w-full">
        <PromptListCards
          createPrompt={createPrompt}
          prompts={prompts}
          selectedPrompt={selectedPrompt}
          setSelectedPrompt={setSelectedPrompt}
        />
        {selectedPrompt !== null ? (
          <Prompt promptId={selectedPrompt} refetchPrompts={refetchPrompts} />
        ) : (
          <div className="prompt-content-wrapper text-gray-1325 text-[16px] pl-[60px] pt-[30px]">
            {t('prompts.noPromptActif')}
          </div>
        )}
        {application?.publiee && (
          <div
            className={`md:block absolute hidden bottom-[60px] right-[60px] hover:cursor-pointer ${isDarkTheme ? 'text-black' : 'text-white'}`}
            onClick={goToIasuite}
          >
            <GoToIasuite />
          </div>
        )}
      </div>
      <PromptMobileMenu
        application={application}
        goToIasuite={goToIasuite}
        createPrompt={createPrompt}
      />
    </>
  )
}
