import { UseFormGetValues } from 'react-hook-form'
import { PromptForm } from 'core/interfaces/Prompt'
import { useEffect, useRef, useState } from 'react'
import { IConverseBody } from 'iastart/interfaces/Conversation'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { useConverseMutation } from 'iastart/api/conversation'
import { PROMPT_TEST_APP } from 'core/constants/general'
import { useNavigate } from 'react-router'
import { IMessage, UserType } from 'core/interfaces/Conversation'
import { usePromptConversation } from 'lauria/contexts/PromptConversationContext'
import { ITestAnswers, PromptTestMessage } from 'core/interfaces/Ask'

export const useBoostPrompt = (
  getFormPromptValues: UseFormGetValues<PromptForm>
) => {
  const navigate = useNavigate()
  const [converse, { isLoading }] = useConverseMutation()
  const messagesContainerRef = useRef<HTMLDivElement | null>(null)
  const { conversation, setConversation } = usePromptConversation()
  const isIMessage = (message: PromptTestMessage): message is IMessage => {
    return 'content' in message && 'timestamp' in message
  }
  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight
    }
  }
  const [userMessage, setUserMessage] = useState('')
  useEffect(() => {
    scrollToBottom()
  }, [conversation, isLoading])
  const getPromptContent = () => {
    return getFormPromptValues('prompt') || ''
  }

  const handleConverse = (message: string) => {
    const lastResult = conversation.messages
      .slice()
      .reverse()
      .find((msg) => 'answers' in msg) as ITestAnswers | undefined
    const resultat1 = lastResult?.answers?.[0]?.answer?.answer ?? ''
    const resultat2 =
      lastResult?.answers && lastResult.answers.length > 1
        ? lastResult.answers[1].answer.answer
        : ''

    let body: IConverseBody = {
      application: PROMPT_TEST_APP,
      params: {
        prompt: JSON.stringify(getPromptContent()),
        opinion: JSON.stringify(message),
        resultat1: JSON.stringify(resultat1),
        resultat2: JSON.stringify(resultat2),
      },
    }
    converse(body)
      .unwrap()
      .then((response) => {
        const messages: PromptTestMessage[] = []
        messages.push({
          type: UserType.USER,
          timestamp: Date.now(),
          content: message,
        })

        messages.push({
          type: UserType.ASSISTANT,
          timestamp: Date.now(),
          content: response.answer,
          id: response.message_id,
        })
        setConversation((prev) => ({
          messages: [...prev.messages, ...messages],
        }))
      })
      .catch((error) => {
        console.error(error)
        navigate(ROUTE_PATHS.ERROR)
      })
  }

  return {
    handleConverse,
    isLoading,
    conversation,
    messagesContainerRef,
    isIMessage,
    userMessage,
    setUserMessage,
  }
}
