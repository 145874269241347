import { CurrentTagProvider } from 'lauria/contexts/CurrentTagContext'
import React from 'react'
import { useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'core/constants/routePath'
import { TagsAdmin } from 'lauria/components/tags/TagsAdmin'
import { UseKeycloakService } from 'core/packages/KeycloakProvider'
import { KEYCLOAK_ROLE_SUPERADMIN } from 'core/constants/role'

export const Tags = () => {
  const kc = UseKeycloakService()
  const navigate = useNavigate()

  const isCurrentUserSuperadmin = kc.checkRole(KEYCLOAK_ROLE_SUPERADMIN)

  if (!isCurrentUserSuperadmin) {
    navigate(ROUTE_PATHS.DASHBOARD)
  }
  return (
    <CurrentTagProvider>
      <TagsAdmin />
    </CurrentTagProvider>
  )
}
